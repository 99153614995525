<div fxFill fxLayout="column">
  <ca-dlg-header [headerTitle]="'SETTINGS.ABOUT_ADMINISTRATION' | translate" [hideInfoButton]="true"></ca-dlg-header>

  <div fxFlex class="mg-4-top" mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="none center">
      <div fxFlex class="bold">{{ 'SETTINGS.SELECT_LANG' | translate }}</div>
      <ca-language></ca-language>
    </div>

    <div class="mg-3-top" fxLayout="row">
      <span fxFlex="nogrow" class="bold">{{ 'SETTINGS.CURRENT_VERSION' | translate }}</span>
      <span fxFlex class="bold version" fxLayoutAlign="end">{{ definitions.APP_VERSION }}</span>
      <span class="mg-4-left" *ngIf="!(updateAvailable$ | async) && (checked$ | async)">{{
        'SETTINGS.ON_NEWEST_VERSION' | translate
      }}</span>
    </div>

    <!-- Enthält die Form zur Steuerung der TouchID Anmeldung unter Cordova -->
    <form [formGroup]="settingsForm" *ngIf="touchIDLoginAvailable()">
      <div class="mg-5-top" fxLayout="row" fxLayoutAlign="none center">
        <span fxFlex class="bold">{{ 'SETTINGS.ALLOW_TOUCH_ID' | translate }}</span>

        <mat-slide-toggle formControlName="activateTouch">
          {{ 'SETTINGS.SAVE_LOGIN' | translate }}
        </mat-slide-toggle>
      </div>
    </form>
  </div>

  <div mat-dialog-actions fxLayoutAlign="end center">
    <button *ngIf="swIsEnabled$ | async" mat-button (click)="searchUpdates()" testid="btnSearchUpdates">
      {{ 'SETTINGS.CHECK_FOR_UPDATES' | translate }}
    </button>
    <button mat-button (click)="clearCache()" testid="btnClearCache">{{ 'SETTINGS.CLEAR_CACHE' | translate }}</button>
  </div>
</div>
