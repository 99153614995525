import { GenericMap } from 'src/app/core/utils/utility-types';
import { UserGroup, UserGroupListView } from 'src/app/entity/usergroup';
import { UserGroupToUpdate } from '../../usergroup-storage.service';

export class LoadGroups {
  static readonly type = '[GroupState] load Groups';
  constructor(public clientId: number) {}
}

export class SetGroups {
  static readonly type = '[GroupState] set Groups';
  constructor(
    public payload: UserGroupListView[],
    public clientId: number,
  ) {}
}

export class SetGroupsLoadFailed {
  static readonly type = '[GroupState] set GroupsLoadFailed';
  constructor(public clientId: number) {}
}

export class CreateGroup {
  static readonly type = '[GroupState] create Group';
  constructor(
    public clientId: number,
    public usergroup: UserGroup,
  ) {}
}

export class PatchGroups {
  static readonly type = '[GroupState] patch Groups';
  constructor(
    public clientId: number,
    public payload: GenericMap<Partial<UserGroup>>,
  ) {}
}

export class PatchGroup {
  static readonly type = '[GroupState] patch Group';
  constructor(
    public clientId: number,
    public groupId: number,
    public payload: Partial<UserGroup>,
  ) {}
}

export class PatchGroupsWithUsers {
  static readonly type = '[GroupState] update Groups with Users';
  constructor(
    public clientId: number,
    public userGroups: UserGroup[],
    public usergroupsToUpdate: GenericMap<UserGroupToUpdate>,
  ) {}
}

export class DeleteGroupsWithUsers {
  static readonly type = '[GroupState] delete groups with users ';
  constructor(
    public clientId: number,
    public groupIdsToDelete: number[],
    public deletedUserIds: number[],
  ) {}
}

export class RemoveUsersFromGroup {
  static readonly type = '[GroupState] remove users from groups';
  constructor(
    public clientId: number,
    public groupsToUpdate: GenericMap<number[]>,
    public userIdsToRemove: number[],
  ) {}
}
