import { Selector, Action, StateContext, State, Store } from '@ngxs/store';
import { EntityStoreState } from './application.state';
import { User } from 'src/app/entity/user';
import * as userActions from './actions/user.action';
import * as clientActions from './actions/client.action';
import * as groupActions from './actions/group.action';
import { GenericMap } from '../../utils/utility-types';
import { arrayToMap } from '../../utils/array-utils';
import { patchClient, putUser, putUsers, putUsersAndDeleted } from '../../utils/state-utils';
import { DateService } from '../../service/date.service';
import { Injectable } from '@angular/core';

export interface UserStateModel {
  [cId: number]: UserModel;
}

interface UserModel {
  deletedUsers: EntityStoreState<User>;
  users: EntityStoreState<User>;
}

export const USER_STATE_NAME = 'user';

@State<UserStateModel>({
  name: USER_STATE_NAME,
})
@Injectable()
export class UserState {
  constructor(
    private dateService: DateService,
    private store: Store,
  ) {}

  /**
   * Gibt die user Daten zurück
   */
  @Selector()
  static selectUserState(state: UserStateModel): (id: number) => EntityStoreState<User> {
    return (id: number) => {
      return state[id].users;
    };
  }

  /**
   * gibt die deletedUser Daten zurück
   */
  @Selector()
  static selectUserStateDeleted(state: UserStateModel): (id: number) => EntityStoreState<User> {
    return (id: number) => {
      return state[id].deletedUsers;
    };
  }

  /**
   * gibt einen User zurück
   */
  @Selector()
  static selectUser(state: UserStateModel): (userId: number, clientId: number) => User {
    return (userId: number, clientId: number) => {
      const userState = this.selectUserState(state)(clientId);
      return userState.data ? userState.data[userId] : null;
    };
  }

  /**
   * Setzt die ClientId
   */
  @Action(clientActions.SetClient)
  setClient(ctx: StateContext<UserStateModel>, action: clientActions.SetClient): void {
    const clientId = action.payload;
    ctx.patchState({
      [clientId]: {
        deletedUsers: {
          data: null,
          status: 'IDLE',
        },
        users: {
          data: null,
          status: 'IDLE',
        },
      },
    });
  }

  /**
   * löscht alle ClientIds
   */
  @Action(clientActions.ClearClients)
  clearClients(ctx: StateContext<UserStateModel>): void {
    ctx.setState({});
  }

  /**
   * Setzt den user Status auf 'LOADING' und lädt die user
   */
  @Action(userActions.LoadUsers)
  loadUsers(ctx: StateContext<UserStateModel>, action: userActions.LoadUsers): void {
    const clientId = action.clientId;
    const oldUserData = ctx.getState()[clientId].users;
    ctx.setState(patchClient<UserModel, UserStateModel>({ users: { ...oldUserData, status: 'LOADING' } }, clientId));
  }

  /**
   * Setzt den user Status auf 'FAILURE'
   */
  @Action(userActions.SetUsersLoadFailed)
  setUsersLoadFailed(ctx: StateContext<UserStateModel>, action: userActions.SetUsersLoadFailed): void {
    const clientId = action.clientId;
    const oldUserData = ctx.getState()[clientId].users;
    ctx.setState(patchClient<UserModel, UserStateModel>({ users: { ...oldUserData, status: 'FAILURE' } }, clientId));
  }

  /**
   * Setzt die user
   */
  @Action(userActions.SetUsers)
  setUsers(ctx: StateContext<UserStateModel>, action: userActions.SetUsers): void {
    const oldUserState: EntityStoreState<User> = ctx.getState()[action.clientId].users;
    const newUserMap: User[] = action.payload;
    const oldUserMap: GenericMap<User> = oldUserState.data ? oldUserState.data : {};

    // Wir patchen die schon geladenen Benutzer nur mit den Daten vom Server,
    // damit ggf. geladen Daten nicht verloren gehen
    const userMap = newUserMap.reduce((map: GenericMap<User>, u: User) => {
      const oldUser: User = oldUserMap[u.id];
      const loadedComplete: boolean = !!(u.loadedComplete || (oldUser && oldUser.loadedComplete));

      map[u.id] = {
        ...oldUser,
        ...u,
        loadedComplete,
      };
      return map;
    }, {});

    ctx.setState(
      patchClient<UserModel, UserStateModel>(
        { users: { ...oldUserState, data: userMap, status: 'SUCCESS' } },
        action.clientId,
      ),
    );
  }

  /**
   * Setzt einen user
   *
   * Action wird auch in usergroupState genutzt
   */
  @Action(userActions.CreateUser)
  createUser(ctx: StateContext<UserStateModel>, { clientId, user }: userActions.CreateUser): void {
    ctx.setState(putUser(clientId, user));
  }

  /**
   * Löscht user und setzt diese als deletedUser
   *
   * Action wird auch in usergroupState genutzt
   */
  @Action(userActions.DeleteUsers)
  deleteUsers(ctx: StateContext<UserStateModel>, { clientId, userIdsToDelete }: userActions.DeleteUsers): void {
    // Benutzer aus der User-Map entfernen
    const users = { ...ctx.getState()[clientId].users.data };
    const deletedUsers: GenericMap<User> = {};
    const deletedDate = this.dateService.newDate().getTime();
    const groupsToUpdate: GenericMap<number[]> = {};
    for (const id of userIdsToDelete) {
      if (users[id] && users[id].usergroupIds) {
        for (const groupId of users[id].usergroupIds) {
          groupsToUpdate[groupId] = groupsToUpdate[groupId] ? [...groupsToUpdate[groupId], id] : [id];
        }
      }
      deletedUsers[id] = {
        ...users[id],
        deletedDate,
      };
      delete users[id];
    }

    // Falls die gelöschten Benutzer schon geladen sind, die gerade gelöschten Benutzer hinzufügen
    const allDeletedUsers = { ...ctx.getState()[clientId].deletedUsers.data, ...deletedUsers };
    ctx.setState(putUsersAndDeleted(clientId, users, allDeletedUsers));
    if (Object.keys(groupsToUpdate).length > 0) {
      this.store.dispatch(new groupActions.RemoveUsersFromGroup(clientId, groupsToUpdate, userIdsToDelete));
    }
  }

  /**
   * Patcht einen user
   */
  @Action(userActions.PatchUser)
  patchUser(ctx: StateContext<UserStateModel>, { clientId, userId, user }: userActions.PatchUser): void {
    const userMap = ctx.getState()[clientId].users.data;
    let userData: User = null;
    if (userMap) {
      userData = userMap[userId];
    }

    if (!userData) {
      throw new Error(`user ${userId} does not exist`);
    }

    ctx.setState(putUser(clientId, { ...userData, ...user }));
  }

  /**
   * Patcht mehrere user
   *
   * TODO pruefen ob User vorhanden. Sonst koennen Partial<User> in den Store
   * gespeichert werden. Ggf. neue Action fuer SetUser erstellen.
   */
  @Action(userActions.PatchUsers)
  patchUsers(ctx: StateContext<UserStateModel>, { clientId, users }: userActions.PatchUsers): void {
    const userState = ctx.getState()[clientId].users;
    const changedUsers: GenericMap<User> = {};

    for (const userId in users) {
      if (Object.prototype.hasOwnProperty.call(users, userId)) {
        const oldUser = userState.data ? userState.data[userId] : null;
        changedUsers[userId] = { ...oldUser, ...users[userId] };
      }
    }
    ctx.setState(putUsers(clientId, changedUsers));
  }

  /**
   * Patcht mehrere user
   *
   * Action wird auch in usergroupState genutzt
   */
  @Action(userActions.PatchUsersWithGroups)
  patchUsersWithGroups(
    ctx: StateContext<UserStateModel>,
    { clientId, users, usersToUpdate }: userActions.PatchUsersWithGroups,
  ): void {
    const userMap = ctx.getState()[clientId].users.data;
    const changedUsers: GenericMap<User> = {};

    for (const user of users) {
      changedUsers[user.id] = { ...userMap[user.id], ...user };
    }
    ctx.setState(putUsers(clientId, changedUsers));
  }

  /**
   * Setzt deletedUser Status auf 'LOADING' und lädt die deletedUser
   */
  @Action(userActions.LoadDeletedUsers)
  loadDeletedUsers(ctx: StateContext<UserStateModel>, action: userActions.LoadDeletedUsers): void {
    const clientId = action.clientId;
    const oldUserData = ctx.getState()[clientId].deletedUsers;
    ctx.setState(
      patchClient<UserModel, UserStateModel>({ deletedUsers: { ...oldUserData, status: 'LOADING' } }, action.clientId),
    );
  }

  /**
   * Definiert, was bei der Aktion 'SetDeletedUsers' durchgeführt werden soll.
   * @param ctx StateContext
   * @param action die durchzuführende Aktion, hier SetDeletedUsers
   */
  @Action(userActions.SetDeletedUsers)
  setDeletedUsers(ctx: StateContext<UserStateModel>, action: userActions.SetDeletedUsers): void {
    const deletedUserMap = arrayToMap(action.payload);
    const oldUserData = ctx.getState()[action.clientId].deletedUsers;
    ctx.setState(
      patchClient<UserModel, UserStateModel>(
        {
          deletedUsers: {
            ...oldUserData,
            data: deletedUserMap,
            status: 'SUCCESS',
          },
        },
        action.clientId,
      ),
    );
  }

  /**
   * Setzt deletedUser Status auf 'FAILURE'
   */
  @Action(userActions.SetDeleteUsersLoadFailed)
  loadDeletedUsersFailed(ctx: StateContext<UserStateModel>, action: userActions.SetDeleteUsersLoadFailed): void {
    const clientId = action.clientId;
    const oldDeletedUserData = ctx.getState()[clientId].deletedUsers;
    ctx.setState(
      patchClient<UserModel, UserStateModel>({ deletedUsers: { ...oldDeletedUserData, status: 'FAILURE' } }, clientId),
    );
  }

  /**
   * patch User
   *
   * Action wird auch in usergroupState genutzt
   */
  @Action(groupActions.PatchGroupsWithUsers)
  patchGroupsWithUsers(
    ctx: StateContext<UserStateModel>,
    { clientId, userGroups, usergroupsToUpdate }: groupActions.PatchGroupsWithUsers,
  ): void {
    const userMap = { ...ctx.getState()[clientId].users.data };

    for (const groupId in usergroupsToUpdate) {
      if (Object.prototype.hasOwnProperty.call(usergroupsToUpdate, groupId)) {
        const usergroupId = parseInt(groupId, 10);
        const groupToUpdate = usergroupsToUpdate[groupId];

        // Hinzugefügte Benutzer updaten
        if (groupToUpdate.userIdsToAdd) {
          for (const userId of groupToUpdate.userIdsToAdd) {
            const user = userMap[userId];
            if (user && user.loadedComplete) {
              const usergroupIds: number[] = user.usergroupIds ? [...user.usergroupIds, usergroupId] : [usergroupId];
              userMap[userId] = { ...user, usergroupIds, lastChange: -1 };
            } else if (user) {
              userMap[userId] = { ...user, lastChange: -1 };
            } else {
              throw new Error(`user with id ${userId} does not exist`);
            }
          }
        }

        // Entfernte Benutzer updaten
        if (groupToUpdate.userIdsToRemove) {
          for (const userId of groupToUpdate.userIdsToRemove) {
            const user = userMap[userId];
            if (user && user.usergroupIds) {
              const usergroupIds = user.usergroupIds.filter((uId) => uId !== usergroupId);
              userMap[userId] = { ...user, usergroupIds, lastChange: -1 };
            } else if (user) {
              userMap[userId] = { ...user, lastChange: -1 };
            } else {
              throw new Error(`user with id ${userId} does not exist`);
            }
          }
        }
      }
    }

    ctx.setState(putUsers(clientId, userMap));
  }

  /**
   * patcht User
   *
   * Action wird auch in usergroupState genutzt
   */
  @Action(groupActions.CreateGroup)
  createGroup(ctx: StateContext<UserStateModel>, { clientId, usergroup }: groupActions.CreateGroup): void {
    // Benutzer aktualisieren, die der Gruppe hinzugefügt worden sind
    const userMap = { ...ctx.getState()[clientId].users.data };
    for (const userId of usergroup.userIds) {
      const user = userMap[userId];
      if (user && user.loadedComplete) {
        const usergroupIds: number[] = user.usergroupIds ? [...user.usergroupIds, usergroup.id] : [usergroup.id];
        userMap[userId] = { ...user, usergroupIds, lastChange: -1 };
      } else if (user) {
        userMap[userId] = { ...user, lastChange: -1 };
      } else {
        throw new Error(`user with id ${userId} does not exist`);
      }
    }
    ctx.setState(putUsers(clientId, userMap));
  }

  /**
   * Löscht user und setzt diese als deletedUser
   */
  @Action(groupActions.DeleteGroupsWithUsers)
  deleteGroupsWithUsers(
    ctx: StateContext<UserStateModel>,
    { clientId, groupIdsToDelete, deletedUserIds }: groupActions.DeleteGroupsWithUsers,
  ): void {
    const userMap: GenericMap<User> = { ...ctx.getState()[clientId].users.data };
    const deletedUsers: GenericMap<User> = {
      ...ctx.getState()[clientId].deletedUsers.data,
    };
    const groupsToUpdate: GenericMap<number[]> = {};

    // Gelöschte Benutzer updaten
    deletedUserIds
      .map((id: number): User => userMap[id])
      // nur user loeschen die exitieren
      .filter((user: User): boolean => !!user)
      .forEach((user: User) => {
        if (user.usergroupIds) {
          for (const groupId of user.usergroupIds) {
            groupsToUpdate[groupId] = groupsToUpdate[groupId] ? [...groupsToUpdate[groupId], user.id] : [user.id];
          }
        }
        deletedUsers[user.id] = { ...user };
        delete userMap[user.id];
      });

    ctx.setState(putUsersAndDeleted(clientId, userMap, deletedUsers));

    if (Object.keys(groupsToUpdate).length > 0) {
      this.store.dispatch(new groupActions.RemoveUsersFromGroup(clientId, groupsToUpdate, deletedUserIds));
    }
  }

  /**
   * Entfernt Gruppen aus Nutzern. Wird beim Löschen von Gruppen genutzt
   *
   * Action wird auch in userState genutzt
   */
  @Action(userActions.RemoveGroupsFromUsers)
  removeGroupsFromUsers(
    ctx: StateContext<UserStateModel>,
    { clientId, userIds, groupIdsToRemove }: userActions.RemoveGroupsFromUsers,
  ): void {
    const userMap: GenericMap<User> = { ...ctx.getState()[clientId].users.data };
    const groupIdsToRemoveSet: Set<number> = new Set(groupIdsToRemove);

    /**
     * Bei den Benutzer die usergroupIds aktualisieren
     * und lastChange auf -1 setzten, damit er beim
     * nächsten Abgleich neu vom Server geladen wird
     */
    for (const userId of userIds) {
      if (userMap[userId]) {
        const user = userMap[userId];
        if (user.usergroupIds) {
          const usergroupIds = user.usergroupIds.filter((gId) => !groupIdsToRemoveSet.has(gId));
          userMap[userId] = { ...user, usergroupIds, lastChange: -1 };
        } else {
          userMap[userId] = { ...user, lastChange: -1 };
        }
      }
    }

    ctx.setState(putUsers(clientId, userMap));
  }
}
