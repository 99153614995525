import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, Observable } from 'rxjs';
import { startWith, first, reduce } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/core/service/environment.service';
import { LocalStorageService } from 'src/app/core/service/local-storage.service';
import { LockService } from 'src/app/core/service/lock.service';
import { AppStorageService } from 'src/app/core/statemanagement/app-storage.service';
import { WINDOW_REF } from 'src/app/core/utils/injection-tokens';
import { UsercacheService, USERCACHE_STORAGE } from 'src/app/module/user/service/usercache.service';

/**
 * Dialog mit Einstellungsmöglichkeiten
 */
@Component({
  selector: 'ca-settings-dial',
  templateUrl: './settings-dial.component.html',
  styleUrls: ['./settings-dial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsDialComponent implements OnInit, OnDestroy {
  /** Gibt an ob der service Worker verfügbar und aktiviert ist */
  swIsEnabled$ = new BehaviorSubject<boolean>(false);
  /** Aktuelle Version der App  */
  definitions = this.envService.getDefinitions();
  /** Gibt an ob bereits nach einem neuem Update gesucht wird  */
  checked$ = new BehaviorSubject<boolean>(false);
  /** Gibt an ob ein Update verfügbar ist  */
  updateAvailable$: Observable<boolean>;
  /** Form für die TouchID Funktion unter Cordova. */
  settingsForm: UntypedFormGroup;

  constructor(
    private swUpdate: SwUpdate,
    private envService: EnvironmentService,
    private localStorage: LocalStorageService,
    private appService: AppStorageService,
    private lockService: LockService,
    private dialogRef: MatDialogRef<SettingsDialComponent>,
    private fb: UntypedFormBuilder,
    @Inject(USERCACHE_STORAGE) private userCache: UsercacheService,
    @Inject(WINDOW_REF) private window: Window,
  ) {
    this.swIsEnabled$.next(this.swUpdate.isEnabled);
    this.settingsForm = this.fb.group({
      activateTouch: [this.localStorage.getOrDefault(this.lockService.LOCK_COOKIE_NAME, false)],
    });
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.updateAvailable$ = this.swUpdate.versionUpdates.pipe(
      reduce((old, event) => old || event.type === 'VERSION_READY', false),
      startWith(false),
    );

    this.settingsForm.controls.activateTouch.valueChanges.subscribe((useTouch) => {
      if (useTouch) {
        this.appService
          .selectSession()
          .pipe(first())
          .subscribe((session) => {
            this.lockService.activateTouchIDVerification(session);
          });
      } else {
        this.lockService.deactivateTouchIDVerification();
      }
    });
  }

  /**
   * Prüft am LockService, ob die TouchID Funktion zur Verfügung steht.
   */
  touchIDLoginAvailable(): boolean {
    return this.lockService.isTouchIDAvailable();
  }

  /**
   * Sucht beim ServiceWorker nach Updates
   */
  searchUpdates(): void {
    this.swUpdate.checkForUpdate().then(() => this.checked$.next(true));
  }

  /**
   * Leert den Cache und lädt anschließend die Anwendung neu.
   */
  clearCache(): void {
    this.userCache.clearAll().subscribe(() => {
      this.dialogRef.close();
      // OP 47005: Kein location.reload(), da es ansonsten zum App Crash unter Cordova kommt.
      // Weiterhin ist an dieser Stelle auch das Neuladen der aktuellen URL nicht richtig, sondern eine Navigation auf index.html notwendig.
      this.window.location.href = 'index.html';
    });
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.swIsEnabled$.complete();
    this.checked$.complete();
  }
}
