import { SortItem, FilterItem } from 'src/app/entity/search';
import { User } from 'src/app/entity/user';
import { UserGroupListView } from 'src/app/entity/usergroup';

export type Comparator<T> = (e1: T, e2: T) => number;

export function NUMBER_COMPARATOR(n1: number, n2: number): number {
  return n1 - n2;
}

export function STR_IGNORE_CASE_COMPARATOR(str1: string, str2: string): number {
  // Leere Einträge werden nach unten sortiert
  if (str1 && str1 !== '') {
    const v1 = str1.toLocaleLowerCase();
    return str2 && str2 !== '' ? v1.localeCompare(str2.toLocaleLowerCase()) : -1;
  }
  return str2 && str2 !== '' ? 1 : 0;
}

/**
 *
 */
export function SEARCH_USER(allUser: User[], searchString: string): User[] {
  searchString = searchString.toLowerCase();
  return allUser.filter((user) => {
    return (
      user.firstname.toLowerCase().includes(searchString) ||
      user.lastname.toLowerCase().includes(searchString) ||
      (user.additionalInformation && user.additionalInformation.toLowerCase().includes(searchString)) ||
      user.cocuunId.includes(searchString) ||
      user.email.toLocaleLowerCase().includes(searchString)
    );
  });
}

/**
 *
 */
export function SEARCH_GROUPS(allUsergroups: UserGroupListView[], searchString: string): UserGroupListView[] {
  searchString = searchString.toLowerCase();
  return allUsergroups.filter(
    (group) =>
      group.name.toLowerCase().includes(searchString) ||
      (group.description && group.description.toLocaleLowerCase().includes(searchString)),
  );
}

/**
 * Filtert Elemente anhand der aktiven Filter
 *
 * @param arr Alle Elemente die Gefiltert werden sollen
 * @param filters Alle an/abwählbaren Filter
 */
export function FILTER_ELEMENTS<T>(arr: T[], filters: FilterItem[]): T[] {
  // Nur über Aktive Filter filtern
  // Entweder value === true oder reverseFilter === true (XOR)
  filters = filters.filter((f) => !f.value !== !f.reverseFilter);
  if (filters.length > 0) {
    // Wir filtern alle Elemente raus
    return arr.filter((e) =>
      // bei denen nicht jeder Filter ein match ist
      filters.every((f) => f.filterFn(e)),
    );
  }
  return arr;
}

/**
 * Führt die Sortierung von Benutzern durch.
 *
 * @param sortValues Mögliche Typen die für die Sortierung bereitstehen
 * @param a Datensatz anhand dessen die Sortierung durchgeführt wird
 * @param b Datensatz anhand dessen die Sortierung durchgeführt wird
 */
export function SORT_USER(sortValues: SortItem[]): (a: User, b: User) => number {
  const sortValue = sortValues.find((sItem) => sItem.value);
  if (null != sortValue) {
    return (a, b) => {
      let value = sortValue.sortFn(a, b);
      if (value === 0) {
        value = SORT_USER_BY_NAME(a, b) * sortValue.order;
      }
      return value;
    };
  }
  return () => 0;
}

export function SORT_USER_BY_NAME(a: User, b: User): number {
  const value = STR_IGNORE_CASE_COMPARATOR(a.lastname, b.lastname);
  return value ? value : STR_IGNORE_CASE_COMPARATOR(a.firstname, b.firstname);
}

/**
 * Führt die Sortierung von Gruppen durch.
 *
 * @param sortValues Mögliche Typen die für die Sortierung bereitstehen
 * @param a Datensatz anhand dessen die Sortierung durchgeführt wird
 * @param b Datensatz anhand dessen die Sortierung durchgeführt wird
 */
export function SORT_GROUPS(sortValues: SortItem[]): (a: UserGroupListView, b: UserGroupListView) => number {
  const sortValue = sortValues.find((sItem) => sItem.value);
  if (null != sortValue) {
    return (a, b) => {
      let value = sortValue.sortFn(a, b);
      if (value === 0) {
        value = STR_IGNORE_CASE_COMPARATOR(a.name, b.name) * sortValue.order;
      }
      return value;
    };
  }
  return () => 0;
}

export function COMPARENUMISNAN(a: number, b: number): number {
  if (Number.isNaN(a)) {
    a = 0;
  }
  if (Number.isNaN(b)) {
    b = 0;
  }
  return NUMBER_COMPARATOR(a, b);
}
