import { Pipe, PipeTransform } from '@angular/core';
import { escapeHtml } from '../utils/string-utils';

/**
 * Makiert einen Teil eines Stringes, nach dem gesucht wird
 */
@Pipe({
  name: 'highlightSearch',
})
export class HighlightSearchPipe implements PipeTransform {
  /**
   * Setzt <mark> HTML-Tags um des gesuchten Substring, wenn im Ausgangsstring vorhanden
   * @param value String, in dem ein Teil markiert werden soll
   * @param searchValue String, der in value makiert wird
   */
  transform(value: string, searchValue: string): string {
    if (searchValue && value) {
      // Escapen, damit auch escapte Texte gehighlighted werden
      const escapedSearchValue = escapeHtml(searchValue);
      const startIndex = value.toLowerCase().indexOf(escapedSearchValue.toLowerCase());
      if (startIndex !== -1) {
        const endLength = escapedSearchValue.length;
        const matchingString = value.substr(startIndex, endLength);
        value = value.replace(matchingString, '<mark>' + matchingString + '</mark>');
      }
    }
    return value;
  }
}
