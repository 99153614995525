import { Pipe, PipeTransform } from '@angular/core';
import { CocuunIdService } from '../service/cocuun-id.service';

/**
 * Formatiert die SsoId eines Benutzers zur Cocuun-Id
 */
@Pipe({
  name: 'formatCocuunId',
})
export class FormatCocuunIdPipe implements PipeTransform {
  constructor(private cocuunIdService: CocuunIdService) {}

  /**
   * Wandelt eine SsoId in eine Cocuun-Id
   * @param ssoid Die SsoId des Nutzers
   */
  transform(ssoid: number): string {
    let formattedSsoId = this.cocuunIdService.formatSsoID(ssoid, 1);
    formattedSsoId = formattedSsoId.replace(new RegExp(' ', 'g'), '-');
    return formattedSsoId + '-' + this.cocuunIdService.calcCheckDigit(ssoid);
  }
}
