import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppStorageService } from '../statemanagement/app-storage.service';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';

/**
 * Verhindert das Einsteigen auf einer anderen Seite als der Login-Seite, wenn keine Session vorhanden ist
 */
@Injectable({
  providedIn: 'root',
})
export class SessionCheckGuard {
  constructor(
    private router: Router,
    private appStorage: AppStorageService,
  ) {}

  /**
   * Navigiert auf die Loginseite, wenn keine Session da ist
   */
  canActivate(): Observable<boolean> {
    return this.appStorage.selectSession().pipe(
      // Undefined ist der Anfangszustand
      filter((session) => session !== undefined),
      // Nur wenn eine Session da ist koennen wir die Anwendung betreten
      map((session) => !!session),
      tap((canActivate) => {
        // Wenn keine Session aktiv ist
        if (!canActivate) {
          // Redirecten wir auf die LoginSeite
          this.router.navigate(['/']);
        }
      }),
    );
  }
}
