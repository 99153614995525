import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { AlertDialComponent } from 'src/app/common-ui/modal/alert-dial/alert-dial.component';
import { AppStorageService } from '../statemanagement/app-storage.service';
import { HttpService, LoginResponse } from './http.service';
import { LockService } from './lock.service';
import { Customer, Identity, SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private httpService: HttpService,
    private sessionService: SessionService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private router: Router,
    private appStorage: AppStorageService,
    private lockService: LockService,
  ) {}

  /**
   * Fuehrt einen Login durch. Dazu wird zuerst am SSO ein Token abgerufen und
   * die Logindaten geprueft. Danach werden mit dem Token die Identities vom SSO
   * abgerufen. Fuer jede Identity werden dann die Benutzerdaten am Mandantensystem
   * abgerufen.
   *
   * @param email die Email des Benutzers
   * @param password das Password des Benutzers
   * @param persist gibt an ob man der Nutzer eingeloggt beliebn will
   * @returns Liste aller Benutzer mit Administrationsrechten
   */
  login(email: string, password: string, persist: boolean): Observable<Customer[]> {
    return this.httpService.login(email, password).pipe(
      tap((loginResp: LoginResponse): void => this.sessionService.initSession(loginResp, email, password, persist)),
      map((loginResp: LoginResponse): Identity[] => loginResp.identities),
      // check if identites are available
      map((identities: Identity[]): Identity[] => {
        if (!identities.length) {
          throw new Error('no identities received');
        }

        return identities;
      }),
      // create list of requests for customers
      map((identities: Identity[]): Observable<Identity>[] =>
        identities.map(
          (identity: Identity): Observable<Identity> =>
            this.httpService.getCore<Customer>(identity, 'rest/v1/users/self').pipe(
              // needed to sort by client
              map((customer: Customer): Identity => {
                identity.client.customer = customer;
                return identity;
              }),
            ),
        ),
      ),
      // wait for all requests
      flatMap((identities$: Observable<Identity>[]): Observable<Identity[]> => forkJoin(identities$)),
      // filter admin & userManager & groupManager customers
      map((identities: Identity[]): Identity[] =>
        identities.filter(
          (identity: Identity): boolean =>
            identity.client.customer.admin ||
            identity.client.customer.userManager ||
            identity.client.customer.groupManager,
        ),
      ),
      map((identities: Identity[]): Identity[] => {
        if (!identities.length) {
          // Zeigt einen Dialog, wenn das Konto keine Administratoren-Rechte besitzt
          this.dialog.open(AlertDialComponent, {
            data: {
              title: this.translate.instant('ERR.LOGIN_DENIED'),
              body: this.translate.instant('ERR.NO_ADMIN_RIGHTS'),
              btnLbl: this.translate.instant('DIALOG.OK'),
            },
            autoFocus: false,
          });

          throw new Error('no identities with admin rights found');
        }

        // set identites in session
        this.sessionService.setIdentities(identities);
        return identities;
      }),
      // sort and map to customers
      map((identities: Identity[]): Customer[] =>
        identities
          .sort((a, b) => a.client.name.localeCompare(b.client.name))
          .map((identity: Identity) => identity.client.customer),
      ),
    );
  }

  /**
   * Loggt den User aus, in dem erst die Session zurückgesetzt wird
   * und auf die Startseite navigiert wird.
   * Danach werden alle aktiven subscribtions in den Storage services gecleart.
   */
  logout(): void {
    this.lockService.deactivateTouchIDVerification().then((_) => {
      this.httpService.logout();
      this.sessionService.clear();
      this.router.navigate(['']).then(() => {
        this.appStorage.clearClients();
      });
    });
  }
}
