import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import { PasswordComponent } from './password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PasswordComponent],
  imports: [MaterialModule, TranslateModule, CommonModule, FormsModule, ReactiveFormsModule],
  exports: [PasswordComponent],
})
export class PasswordModule {}
