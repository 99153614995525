<div fxFlex fxLayout="row" fxLayoutAlign="none center">
  <mat-icon svgIcon="mdi:lock_outline" class="prefix-icon"></mat-icon>
  <form fxFlex [formGroup]="passwordForm">
    <mat-form-field fxFlex fxFill>
      <mat-label>{{ 'LOGIN.PASSWORD' | translate }}</mat-label>
      <input
        matInput
        [type]="showPw ? 'text' : 'password'"
        testid="edtPassword"
        autocomplete="new-password"
        (keyup.enter)="keyupEnter.emit($event)"
        formControlName="password"
        spellcheck="false"
      />
    </mat-form-field>
  </form>

  <button mat-icon-button (click)="showPw = !showPw" [color]="showPw ? 'accent' : 'primary'" type="button">
    <mat-icon svgIcon="mdi:visibility"></mat-icon>
  </button>
</div>
