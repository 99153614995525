import { Injectable, Inject } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialComponent } from 'src/app/common-ui/modal/confirm-dial/confirm-dial.component';
import { TranslateService } from '@ngx-translate/core';
import { WINDOW_REF } from '../utils/injection-tokens';
import { switchMap } from 'rxjs/operators';

/**
 * Registriert sich am updatesAvailable Observable vom service worker,
 * damit bei existierenden App updates ein Dialog geöffnet wird,
 * welcher den User fragt, ob er die App jetzt Aktualisieren möchte
 */
@Injectable({
  providedIn: 'root',
})
export class PromptUpdateService {
  constructor(
    private updates: SwUpdate,
    private dialogService: MatDialog,
    private translate: TranslateService,
    @Inject(WINDOW_REF) private window: Window,
  ) {}

  /**
   * Hier wird einmal eine Subscription am updates available Observable des Service Workers
   * gesetzt, um bei existierenden neuen updates einen Dialog auslösen, welcher den Nutzer fragt,
   * ob er die Anwendung jetzt aktualisieren möchte. Bestätigt der Nutzer diese Abfrage
   * wird der Service Worker angestoßen, das update auszführen und anschließend wird die
   * Anwendung neu geladen.
   */
  initialize(): void {
    // Subscriben auf dem updatesAvailable Observable
    this.updates.versionUpdates
      .pipe(
        switchMap((event) => {
          if (event.type === 'VERSION_READY') {
            console.log('Current version: ' + JSON.stringify(event.currentVersion));
            console.log('Available version: ' + JSON.stringify(event.latestVersion));
            return this.dialogService
              .open(ConfirmDialComponent, {
                data: {
                  title: this.translate.instant('UPDATE.DLG_TITLE'),
                  body: this.translate.instant('UPDATE.UPDATE_AVAILABLE'),
                  confirmBtnLbl: this.translate.instant('DIALOG.YES'),
                  cancelBtnLbl: this.translate.instant('DIALOG.NO'),
                },
              })
              .afterClosed();
          }
          return null;
        }),
      )
      // Nach dem schließen des Dialogs
      .subscribe((updateNow: boolean) => {
        // ... wird geprüft, ob der user jetzt aktualisieren möchte
        if (updateNow) {
          // wenn ja wird das update ausgeführt und die Seite neu geladen
          this.updates.activateUpdate().then(() => this.window.location.reload());
        }
      });
  }
}
