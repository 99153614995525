import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatCocuunIdPipe } from './format-cocuun-id.pipe';
import { HighlightSearchPipe } from './highlight-search.pipe';
import { FormatFilesizePipe } from './format-filesize.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
  declarations: [FormatCocuunIdPipe, HighlightSearchPipe, FormatFilesizePipe, SafeHtmlPipe],
  imports: [CommonModule],
  exports: [FormatCocuunIdPipe, HighlightSearchPipe, FormatFilesizePipe, SafeHtmlPipe],
  providers: [FormatCocuunIdPipe, HighlightSearchPipe, FormatFilesizePipe, SafeHtmlPipe],
})
export class PipesModule {}
