<div class="window" fxLayout="column">
  <div
    *ngIf="params$ | async as params"
    class="header pad-4-horz"
    fxFlex="nogrow"
    fxLayout="row"
    fxLayoutAlign="none center"
  >
    <div
      [class]="params.showUserProfile ? 'leftBoxWide' : 'leftBox'"
      [fxFlex]="params.showUserProfile ? '' : 'nogrow'"
      fxLayout="row"
      fxLayoutAlign="start"
    >
      <ng-container *ngIf="!params.showUserProfile; else showProfile">
        <ca-back-button
          *ngIf="!params.hideBack"
          [hidden]="params.hideBack"
          [text]="params.backText"
          [icon]="params.backIcon"
          (back)="params.backFn ? params.backFn() : back()"
        ></ca-back-button>
      </ng-container>
      <ng-template #showProfile>
        <ca-user-profile style="overflow: hidden"></ca-user-profile>
      </ng-template>
    </div>

    <div id="headlineBox" [fxFlex]="params.showUserProfile ? 'nogrow' : ''">
      <h1 testid="lblAppHeadline">
        {{ params.translateHeadline ? (params.headline | translate) : params.headline }}
      </h1>
      <img *ngIf="!params.headline" src="assets/logo.png" id="headerImage" (click)="searchUpdates()" />
    </div>
    <div
      [class]="params.showUserProfile ? 'rightBoxWide' : 'rightBox'"
      [fxFlex]="params.showUserProfile ? '' : 'nogrow'"
      fxLayout="row"
      fxLayoutAlign="end"
    >
      <div
        id="infoBtn"
        class="dlg-info-button"
        fxLayoutAlign="center center"
        (click)="openInfoDialog(params.infoTextTitle, params.infoTextContent)"
      >
        <button mat-icon-button>
          <mat-icon svgIcon="mdi:help_outline"></mat-icon>
        </button>
      </div>
      <button
        id="homeBtn"
        mat-stroked-button
        *ngIf="params.showHomeBtn"
        (click)="goHome()"
        testid="btnHome"
        class="mg-1-top"
      >
        <mat-icon svgIcon="mdi:home" color="primary"></mat-icon>
      </button>
      <button
        id="settingsBtn"
        mat-stroked-button
        *ngIf="params.showSettingsBtn"
        (click)="openSettings()"
        class="mg-1-top"
      >
        <mat-icon svgIcon="mdi:settings" color="primary"></mat-icon>
      </button>
    </div>
  </div>
  <div
    id="contentWrapper"
    fxFlex
    [@routeAnimation]="{ value: prepRouteState(routerOutlet) }"
    (@routeAnimation.start)="captureStartEvent($event)"
    (@routeAnimation.done)="captureDoneEvent($event)"
  >
    <router-outlet #routerOutlet="outlet"></router-outlet>
  </div>
</div>
