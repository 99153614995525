<mat-form-field id="langSelectField" [ngClass]="smallVariant ? 'smallSelectField' : ''">
  <mat-select [ngModel]="selectedLang" (ngModelChange)="onLangChange($event)" testid="selLanguage" panelWidth="">
    <mat-select-trigger fxLayoutAlign="center center">
      <mat-icon [svgIcon]="selectedLang.icon"></mat-icon>&nbsp;{{ getLanguageTitle(selectedLang) }}
    </mat-select-trigger>
    <mat-option *ngFor="let lang of languages" [value]="lang">
      <mat-icon [svgIcon]="lang.icon"></mat-icon>{{ getLanguageTitle(lang) }}
    </mat-option>
  </mat-select>
</mat-form-field>
