import { User } from 'src/app/entity/user';
import { GenericMap } from 'src/app/core/utils/utility-types';
import { UserToUpdate } from '../../user-storage.service';
import { CustomerInfo } from 'src/app/entity/usergroup';

export class LoadUsers {
  static readonly type = '[UserState] load Users';
  constructor(public clientId: number) {}
}

export class SetUsersLoadFailed {
  static readonly type = '[UserState] set UsersLoadFailed';
  constructor(public clientId: number) {}
}

export class SetUsers {
  static readonly type = '[UserState] set UserState';
  constructor(
    public clientId: number,
    public payload: User[],
  ) {}
}

export class CreateUser {
  static readonly type = '[UserState] set User';
  constructor(
    public clientId: number,
    public user: User,
    public customerInfoMap: GenericMap<CustomerInfo>,
  ) {}
}

export class DeleteUsers {
  static readonly type = '[UserState] delete Users';
  constructor(
    public clientId: number,
    public userIdsToDelete: number[],
  ) {}
}

export class PatchUser {
  static readonly type = '[UserState] update User';
  constructor(
    public clientId: number,
    public userId: number,
    public user: Partial<User>,
  ) {}
}

export class PatchUsers {
  static readonly type = '[UserState] update Users';
  constructor(
    public clientId: number,
    public users: GenericMap<Partial<User>>,
  ) {}
}

export class PatchUsersWithGroups {
  static readonly type = '[UserState] update Users with groups';
  constructor(
    public clientId: number,
    public users: User[],
    public usersToUpdate: GenericMap<UserToUpdate>,
    public customerInfoMap: GenericMap<CustomerInfo>,
  ) {}
}

export class RemoveGroupsFromUsers {
  static readonly type = '[UserState] remove groups from users';
  constructor(
    public clientId: number,
    public userIds: number[],
    public groupIdsToRemove: number[],
  ) {}
}

export class LoadDeletedUsers {
  static readonly type = '[DeletedUserState] loadDeletedUsers';
  constructor(public clientId: number) {}
}

export class SetDeletedUsers {
  static readonly type = '[DeletedUserState] setDeletedUsers';
  constructor(
    public clientId: number,
    public payload: User[],
  ) {}
}

export class SetDeleteUsersLoadFailed {
  static readonly type = '[DeletedUserFailedState] setDeletedUsersFailed';
  constructor(public clientId: number) {}
}
