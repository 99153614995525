import { Injectable } from '@angular/core';

/**
 * Service zum Zugriff auf den LocalStorage.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private nativeLS = window.localStorage;

  /**
   * @ignore
   */
  constructor() {}

  /**
   * Liefert einen Wert zum übergebenen Schlüssel.
   * @param key Schlüssel zum Eintrag
   */
  get(key: string): any {
    return JSON.parse(this.nativeLS.getItem(key));
  }

  /**
   * Liefert einen Wert zum übergebenen Schlüssel, oder falls kein Wert vorhanden ist,
   * den übergebenen Default-Wert.
   * @param key Schlüssel zum Eintrag
   * @param defaultVal Fallback-Wert
   */
  getOrDefault(key: string, defaultVal: any): any {
    const value = this.get(key);
    return null == value ? defaultVal : value;
  }

  /**
   * Entfernt einen Eintrag zum übergebenen Schlüssel.
   * @param key Schlüssel zum Eintrag
   */
  remove(key: string): void {
    this.nativeLS.removeItem(key);
  }

  /**
   * Hinterlegt einen Key-Value Wertpaar im lokalen Store.
   * @param key Schlüssel zum Eintrag
   * @param value Wert zum Schlüssel
   */
  set(key: string, value: any): void {
    this.nativeLS.setItem(key, JSON.stringify(value));
  }

  /**
   * Prüft, ob der übergebenen Schlüssel als Schlüssel im lokalen Store vorhanden ist.
   */
  has(key: string): boolean {
    return key in this.nativeLS;
  }
}
