import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserItemComponent } from './user-item/user-item.component';
import { UsergroupItemComponent } from './usergroup-item/usergroup-item.component';
import { MaterialModule } from '../material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { MoreItemComponent } from './more-item/more-item.component';

@NgModule({
  declarations: [UserItemComponent, UsergroupItemComponent, MoreItemComponent],
  imports: [CommonModule, MaterialModule, TranslateModule],
  exports: [UserItemComponent, UsergroupItemComponent, MoreItemComponent],
})
export class ItemsModule {}
