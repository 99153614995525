import { Injectable } from '@angular/core';
import { BvwClient } from 'src/app/module/client/service/client-backend.service';
import { AppStorageService } from '../statemanagement/app-storage.service';
import { Base64Service } from './base64.service';
import { EnvironmentService } from './environment.service';
import { LoginResponse } from './http.service';
import { LocalStorageService } from './local-storage.service';

/**
 * Handelt die Session, des angemeldeten Benutzes
 */
@Injectable({
  providedIn: 'root',
})
export class SessionService {
  /** Feldname für die Session im Localstorage */
  private SESSION_COOKIE_KEY = 'SESSION';

  /** Letzter Wert aus dem session$ Observable vom AppStorageService */
  private lastSessionVal: Session = null;

  constructor(
    private base64Service: Base64Service,
    private localStorage: LocalStorageService,
    private appStorage: AppStorageService,
    private envService: EnvironmentService,
  ) {
    // Laden der Session
    const session = this.restore();
    // Global verfügbar machen
    this.lastSessionVal = session;
    if (null !== session) {
      this.appStorage.setSession(session);
    }
  }

  /**
   * Setzt im Store eine Session, wenn eine im LocalStorage vorhanden war,
   * und persistiert neue/veränderte Sessions, wenn diese persistiert werden sollen
   */
  initialize(): void {
    this.appStorage.setSession(this.lastSessionVal);

    this.appStorage.selectSession().subscribe((session) => {
      this.lastSessionVal = session;
      if (session && session.clientId && session.persist) {
        this.persist();
      }
    });
  }

  /**
   * Gibt den Letzten Wert der Session zurück
   * @deprecated nicht mehr verwenden, stattdessen @link AppStorageService#selectSession verwenden
   */
  get(): Session {
    return this.lastSessionVal;
  }

  /**
   * Erstellt eine neue Session und schreibt diese in den Store
   *
   * @param loginResp Antwort des Login-Requests
   * @param email Email des angemeldeten Benutzers
   * @param password Passwort des Angemeldeten Benutzers
   * @param persist Gibt an, ob die Session persistiert werden soll
   */
  initSession(loginResp: LoginResponse, email: string, password: string, persist: boolean): void {
    const session = this.create();
    session.token = loginResp.token;
    session.email = email;
    session.password = password;
    session.customer = loginResp.customer;
    session.persist = persist;
    this.appStorage.setSession(session);
  }

  /**
   * Fügt die Identities des angemeldeten Benutzes zu der Session im Store hinzu
   *
   * @param identities Identities des Benutzers
   */
  setIdentities(identities: Identity[]): void {
    const identitiesMap = {};
    const externalClientIds = identities.map((identity: Identity) => {
      identitiesMap[identity.client.externalId] = identity;
      return identity.client.externalId;
    });
    this.appStorage.patchSession({ externalClientIds, identitiesMap });
  }

  /**
   * Fügt die ID des Mandanten, für den man sich Anmeldet, zur Session hinzu
   *
   * @param clientId ID des Mandanten
   */
  activateSession(clientId: number): void {
    this.appStorage.patchSession({ clientId });
  }

  /**
   * Setzt ein neues Token bei der Session
   *
   * @param token Token, der neu gesetzt werden soll
   */
  updateToken(token: string): void {
    this.appStorage.patchSession({ token });
  }

  /**
   * Gibt die Identity mit der entsprechenden externalId zurück
   *
   * @param externalId ExternalId des Mandanten zu der Identity, die man haben möchte
   */
  getIdentityByExtId(externalId: number): Identity {
    return this.lastSessionVal.identitiesMap[externalId];
  }

  /**
   * Löscht die Session aus dem Store und aus dem LocalStorage
   */
  clear(): void {
    this.appStorage.setSession(null);
    this.delete();
  }

  private delete(): void {
    this.localStorage.remove(this.SESSION_COOKIE_KEY);
  }

  /**
   * Das Speichern der Session soll nur dann erlaubt werden, wenn es sich nicht um ein Produktivsystem handelt,
   * oder wenn es die Anwendung unter Cordova ausgeführt wird.
   */
  private canPersistSession(): boolean {
    const env = this.envService.getEnvironment();
    return !env.production || env.isCordova;
  }

  /**
   * Erstellt ein leeres Session-Objekt
   */
  private create(): Session {
    return {
      token: null,
      email: null,
      password: null,
      customer: null,
      clientId: null,
      externalClientIds: [],
      identitiesMap: {},
      persist: false,
    };
  }

  /**
   * Gibt zurück, ob eine Session im Localstorage vorhanden ist
   */
  private hasPersistedSession(): boolean {
    if (!this.canPersistSession()) {
      throw new Error('It is not allowed to persist the current session!');
    }
    return this.localStorage.has(this.SESSION_COOKIE_KEY);
  }

  /**
   * Verschlüsselt und persistert die aktuelle Session im Localstorage
   */
  private persist(): void {
    if (!this.canPersistSession()) {
      throw new Error('It is not allowed to persist the current session!');
    }

    if (!this.envService.isCordova) {
      const jsonStr = JSON.stringify(this.lastSessionVal);
      const base64 = this.base64Service.encode(jsonStr, false);
      this.localStorage.set(this.SESSION_COOKIE_KEY, base64);
    }
  }

  /**
   * Holt die persistierte Session aus dem Localstorage und entschlüsselt diese
   */
  private restore(): Session {
    if (this.canPersistSession() && this.hasPersistedSession() && !this.envService.isCordova) {
      const base64 = this.localStorage.get(this.SESSION_COOKIE_KEY);
      const jsonStr = this.base64Service.decode(base64, false);
      return JSON.parse(jsonStr);
    }
    return null;
  }
}

export interface Session {
  token: string;
  email: string;
  password: string;
  customer: CustomerSSO;
  clientId: number; // Die clientId fuer die man sich angemeldet hat
  externalClientIds: number[];
  identitiesMap: { [n: string]: Identity };
  persist: boolean;
}

export interface Client {
  customer: Customer; // bvw_benutzer den wir am client erst anreichern
  endpoint: string;
  externalId: number;
  host: string;
  id: number;
  name: string;
  port: number;
  pro: boolean;
  type: string;
}

export interface CustomerSSO {
  id: number;
  firstname: string;
  lastname: string;
  avatar?: string;
}

export interface Identity {
  id: number;
  client: Client;
  bvwClient?: BvwClient;
}

export interface Customer {
  admin: boolean;
  groupManager: boolean;
  userManager: boolean;
  freeInvite: boolean;
  id: number;
}
