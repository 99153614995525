<h1 mat-dialog-title>
  {{ 'LOGIN.CLIENT_ADMIN_QUESTION' | translate }}
</h1>
<mat-nav-list>
  <mat-list-item
    (click)="openIdentity(data.identityMap[clientId])"
    (keyup.enter)="openIdentity(data.identityMap[clientId])"
    *ngFor="let clientId of data.clientIds"
    tabindex="0"
  >
    {{ data.identityMap[clientId].client.name }}
  </mat-list-item>
</mat-nav-list>
