<div fxLayout="column">
  <ca-dlg-header [headerTitle]="data.title" [hideInfoButton]="true"></ca-dlg-header>

  <div class="mg-4-top" mat-dialog-content>
    <p>
      {{ data.body }}
    </p>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end center">
    <button mat-button [mat-dialog-close]="true" testid="btnConfirmConDlg">{{ data.confirmBtnLbl }}</button>
    <button *ngIf="data.cancelBtnLbl" mat-button [mat-dialog-close]="false" cdkFocusInitial testid="btnCancelConDlg">
      {{ data.cancelBtnLbl }}
    </button>
  </div>
</div>
