import { Selector, Action, StateContext, State } from '@ngxs/store';
import { ClientStatistics, BvwClient } from 'src/app/module/client/service/client-backend.service';
import { StorageStatus } from './application.state';

import * as clientActions from './actions/client.action';
import * as clientInfoActions from './actions/clientInformation.action';
import { UserState } from './user.state';
import { UsergroupState } from './usergroup.state';
import { Injectable } from '@angular/core';

export interface ClientStateModel {
  bvwClient: BvwClientState;
  clientInfo: ClientStatistics;
}

export interface BvwClientState {
  data: BvwClient;
  status: StorageStatus;
}

export const CLIENT_STATE_NAME = 'client';

@State<ClientStateModel>({
  name: CLIENT_STATE_NAME,
  defaults: {
    bvwClient: {
      data: null,
      status: 'IDLE',
    },
    clientInfo: null,
  },
  children: [UsergroupState, UserState],
})
@Injectable()
export class ClientState {
  constructor() {}

  /**
   * gibt den bvwClient zurück
   */
  @Selector()
  static selectBvwClientState(state: ClientStateModel): BvwClientState {
    return state.bvwClient;
  }

  /**
   * gibt die Client Statistiken zurück
   */
  @Selector()
  static selectClientInfo(state: ClientStateModel): ClientStatistics {
    return state.clientInfo;
  }

  /**
   * entfernt die ClientStatistiken und den BvwClient
   */
  @Action(clientActions.ClearClients)
  clearClients(ctx: StateContext<ClientStateModel>): void {
    ctx.setState({
      bvwClient: {
        data: null,
        status: 'IDLE',
      },
      clientInfo: null,
    });
  }

  /**
   * Setzt den bvwClient Status auf 'LOADING' und lädt den bvwClient
   */
  @Action(clientActions.LoadBvwClient)
  loadBvwClient(ctx: StateContext<ClientStateModel>, action: clientActions.LoadBvwClient): void {
    const oldState = ctx.getState().bvwClient;
    ctx.patchState({ bvwClient: { ...oldState, status: 'LOADING' } });
  }

  /**
   * Setzt den bvwClient Status auf 'FAILURE'
   */
  @Action(clientActions.SetBvwClientLoadFailed)
  setBvwClientLoadFailed(ctx: StateContext<ClientStateModel>, action: clientActions.SetBvwClientLoadFailed): void {
    const oldState = ctx.getState().bvwClient;
    ctx.patchState({ bvwClient: { ...oldState, status: 'FAILURE' } });
  }

  /**
   * Setzt den bvwClient
   */
  @Action(clientActions.SetBvwClient)
  setBvwClient(ctx: StateContext<ClientStateModel>, action: clientActions.SetBvwClient): void {
    ctx.patchState({ bvwClient: { data: action.payload, status: 'SUCCESS' } });
  }

  /**
   * Setzt die Client Statistiken
   */
  @Action(clientInfoActions.SetClientStatistics)
  setClientStatistics(ctx: StateContext<ClientStateModel>, action: clientInfoActions.SetClientStatistics): void {
    ctx.patchState({ clientInfo: action.payload });
  }
}
