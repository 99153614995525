import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerSSO, Customer } from 'src/app/core/service/session.service';
import { AppStorageService } from 'src/app/core/statemanagement/app-storage.service';
import { map, filter } from 'rxjs/operators';
import { isPresent } from 'src/app/core/utils/fp-utils';
import { UserService } from 'src/app/core/service/user.service';
import { User } from 'src/app/entity/user';
import { AuthService } from 'src/app/core/service/auth.service';

/**
 * Komponente zur Anzeige des Abmelden Buttons
 * Hier wird der Name des Angemeldeten Benutzer mit entsprechendem Recht und E-mail angezeigt
 */
@Component({
  selector: 'ca-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent implements OnInit {
  hover: boolean;
  ssoCustomer$: Observable<CustomerSSO>;
  private coreCustomer$: Observable<Customer>;
  /** Email des angemeldeten Benutzers */
  customerEmail$: Observable<string>;
  /** Name des angemeldeten Benutzers. */
  customerName$: Observable<string>;
  /** String zur Ausgabe der Rechte des angemeldeten Benutzers. Nur zur anzeige */
  customerRights$: Observable<string>;

  constructor(
    private appStorage: AppStorageService,
    private userService: UserService,
    private authService: AuthService,
  ) {}

  /** @ignore */
  ngOnInit(): void {
    // Hilfsobservable, in dem der ssoCustomer mit der email zusammengesetzt wird
    this.ssoCustomer$ = this.appStorage.selectSession().pipe(
      filter(isPresent),
      map((u) => {
        return {
          ...u.customer,
          email: u.email,
        };
      }),
    );
    // Hilfsobservable, in dem der coreCustomer steht
    this.coreCustomer$ = this.appStorage.selectSession().pipe(
      filter(isPresent),
      map((s) => s.identitiesMap[s.clientId].client.customer),
    );
    // Observable, um die email des ssoCustomer anzuzeigen
    this.customerEmail$ = this.ssoCustomer$.pipe(map((c: any) => c.email));

    this.customerName$ = this.ssoCustomer$.pipe(
      // Da der ssoCustomer den Typ CustomerSSO hat, sind wir uns hier sicher,
      // das er immer firstname und lastname hat
      map((ssoCust) => this.userService.getFullName(ssoCust as User, false)),
    );

    this.customerRights$ = this.coreCustomer$.pipe(
      map((user) => {
        if (user.admin) {
          return 'RIGHTS.ADMIN';
        }
        if (user.groupManager && user.userManager) {
          return 'RIGHTS.USER_AND_GROUP_MANAGER';
        }
        if (user.groupManager) {
          return 'RIGHTS.GROUP_MANAGER';
        }
        if (user.userManager) {
          return 'RIGHTS.USER_MANAGER';
        }
        // Dieser Benutzer hat keine Rechte zur Administration und darf garnicht eingeloggt sein
        throw new Error('This user is not allowed to be logged in');
      }),
    );
  }

  /**
   * Loggt den User aus
   */
  logout(): void {
    this.authService.logout();
  }
}
