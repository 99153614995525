import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface AppEnvironment {
  name: string;
  baseUrl: string;
  basePath: string;
  ssoPort?: string;
  production: boolean;
  persistCaches: boolean;
  isCordova: boolean;
  isAndroid: boolean;
  isIOS: boolean;
  isElectron: boolean;
  additionalHeaders: { [key: string]: any };
}

export interface AppDefinitions {
  APP_VERSION: string;
  APP_ID: string;
  APP_NAME: string;
  AUTHOR_NAME: string;
  AUTHOR_EMAIL: string;
  AUTHOR_HREF: string;
  BUILD_NUMBER: string;
  BUILD_TIME: number;
  DEBUG: boolean;
  VCS_BRANCH: string;
  VCS_HASH: string;
}

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private definitions: AppDefinitions = {
    APP_VERSION: process.env.APP_VERSION,
    APP_ID: process.env.APP_ID,
    APP_NAME: process.env.APP_NAME,
    AUTHOR_NAME: process.env.AUTHOR_NAME,
    AUTHOR_EMAIL: process.env.AUTHOR_EMAIL,
    AUTHOR_HREF: process.env.AUTHOR_HREF,
    BUILD_NUMBER: process.env.BUILD_NUMBER,
    BUILD_TIME: Number(process.env.BUILD_TIME),
    DEBUG: Boolean(process.env.DEBUG),
    VCS_BRANCH: process.env.VCS_BRANCH,
    VCS_HASH: process.env.VCS_HASH,
  };

  /**
   * If environment is electron.
   */
  get isElectron(): boolean {
    return environment.isElectron;
  }

  /**
   * If environment is cordova.
   */
  get isCordova(): boolean {
    return environment.isCordova;
  }

  /**
   * If environment is web.
   */
  get isWeb(): boolean {
    return !environment.isCordova && !environment.isElectron;
  }

  /**
   * If environment is android.
   */
  get isAndroid(): boolean {
    return environment.isAndroid;
  }

  /**
   * If environment is iOS.
   */
  get isIOS(): boolean {
    return environment.isIOS;
  }

  /**
   * If angular runs in production mode.
   */
  get production(): boolean {
    return environment.production;
  }

  /**
   * Get a copy of app definitions.
   *
   * @return A copy of app definitions.
   */
  getDefinitions(): AppDefinitions {
    return {
      ...this.definitions,
    };
  }

  /**
   * Get a copy of app environment.
   *
   * @return A copy of app environment.
   */
  getEnvironment(): AppEnvironment {
    return {
      ...environment,
    };
  }
}
