import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AlertDialogData {
  title: string;
  body: string;
  btnLbl: string;
  error?: string;
}

@Component({
  selector: 'ca-alert-dial',
  templateUrl: './alert-dial.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertDialComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: AlertDialogData) {}
}
