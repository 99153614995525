import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogConfig, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { RAWLANG } from 'src/assets/i18n/lang';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './common-ui/app.component';
import { BackbuttonModule } from './common-ui/back-button/back-button.module';
import { DialogModule } from './common-ui/dialog/dialog.module';
import { FullSizeLoadingSpinnerComponent } from './common-ui/full-size-loading-spinner/full-size-loading-spinner.component';
import { LanguageModule } from './common-ui/language/language.module';
import { MaterialModule } from './common-ui/material/material.module';
import { AlertDialComponent } from './common-ui/modal/alert-dial/alert-dial.component';
import { ConfirmDialComponent } from './common-ui/modal/confirm-dial/confirm-dial.component';
import { ImprintDialComponent } from './common-ui/modal/imprint-dial/imprint-dial.component';
import { InfoDialComponent } from './common-ui/modal/info-dial/info-dial.component';
import { SettingsDialComponent } from './common-ui/modal/settings-dial/settings-dial.component';
import { PasswordModule } from './common-ui/password/password.module';
import { SearchbarModule } from './common-ui/searchbar/searchbar.module';
import { httpInterceptors } from './core/interceptors/http-interceptors';
import { PipesModule } from './core/pipes/pipes.module';
import { ErrorService } from './core/service/error.service';
import { LanguageService } from './core/service/language.service';
import { LocalDatabaseService } from './core/service/local-database.service';
import { PromptUpdateService } from './core/service/prompt-update.service';
import { RequestBundler, RequestFn } from './core/shared/RequestBundler';
import { ApplicationState } from './core/statemanagement/state/application.state';
import { ClientState } from './core/statemanagement/state/client.state';
import { UserState } from './core/statemanagement/state/user.state';
import { UsergroupState } from './core/statemanagement/state/usergroup.state';
import { RAWLANG_TOKEN, RequestBundlerFactory, WINDOW_REF } from './core/utils/injection-tokens';
import { IEntity } from './entity/entity';
import { UserProfileModule } from './module/client/page/user-profile/user-profile.module';
import { IdentitySelectComponent } from './module/login/component/identity-select/identity-select.component';
import { LoginPageComponent } from './module/login/page/login/login-page.component';
import { InMemoryUsercacheService } from './module/user/service/in-memory-usercache.service';
import { PersistentUsercacheService } from './module/user/service/persistent-usercache.service';
import { USERCACHE_STORAGE } from './module/user/service/usercache.service';
import { CAWindow } from './core/utils/window-utils';
import { EnvironmentService } from './core/service/environment.service';
import { AndroidPermissionService } from './core/service/plugins/permission-android.service';
import { PermissionServiceStub } from './core/service/plugins/permission-stub.service';
import { Permission } from './core/service/plugins/permission';
import { MailPreviewComponent } from './common-ui/modal/mail-preview/mail-preview.component';

const DevTools = environment.production ? [] : [NgxsReduxDevtoolsPluginModule.forRoot()];
export type TRACK_BY_FN_TYPE<T> = (idx: number, item: T) => any;
export const TRACK_BY_ID = new InjectionToken<TRACK_BY_FN_TYPE<IEntity>>('trackBy.id');
export const TRACK_BY_ID_FN = (_: number, item: IEntity): number => item && item.id;
export const PERMISSION = new InjectionToken<Permission>('permission');

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    ConfirmDialComponent,
    AlertDialComponent,
    IdentitySelectComponent,
    FullSizeLoadingSpinnerComponent,
    ImprintDialComponent,
    InfoDialComponent,
    SettingsDialComponent,
    MailPreviewComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule.forRoot(),
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxsModule.forRoot([ApplicationState, ClientState, UserState, UsergroupState], {
      developmentMode: !environment.production,
    }),
    NgxsRouterPluginModule.forRoot(),
    TranslateModule.forRoot(),
    DevTools,
    PipesModule,
    BackbuttonModule,
    SearchbarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production && !environment.isElectron && !environment.isCordova,
    }),
    PasswordModule,
    LanguageModule,
    UserProfileModule,
    DialogModule,
  ],
  providers: [
    {
      provide: USERCACHE_STORAGE,
      deps: [WINDOW_REF],
      useFactory: (window: CAWindow) => {
        try {
          if (environment.persistCaches && window.openDatabase) {
            return new PersistentUsercacheService(new LocalDatabaseService(window));
          }
          return new InMemoryUsercacheService();
        } catch (e) {
          return new InMemoryUsercacheService();
        }
      },
    },
    {
      provide: PERMISSION,
      deps: [EnvironmentService, WINDOW_REF],
      useFactory: (envService: EnvironmentService, window: CAWindow) => {
        if (environment.isAndroid) {
          return new AndroidPermissionService(envService, window);
        }
        return new PermissionServiceStub();
      },
    },
    {
      provide: ErrorHandler,
      useClass: ErrorService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (langService: LanguageService) => () => langService.init(),
      deps: [LanguageService],
      multi: true,
    },
    {
      provide: TRACK_BY_ID,
      // Null Check fuer die Kachelansicht, da dort null Elemente in den Rows sind
      useValue: TRACK_BY_ID_FN,
    },
    {
      provide: WINDOW_REF,
      useValue: window,
    },
    {
      provide: RAWLANG_TOKEN,
      useValue: RAWLANG,
    },
    {
      provide: RequestBundlerFactory,
      useValue: (fn: RequestFn<any>) => new RequestBundler<any>(fn),
    },
    httpInterceptors,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { ...new MatDialogConfig(), autoFocus: false },
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  /**
   * Eine Injection könnte hier auch reichen, wenn der initialize code in den Konstruktor wandern würde.
   * Aber zur verdeutlichung wird hier die initialize Funktion aufgerufen.
   */
  constructor(private updateService: PromptUpdateService) {
    this.updateService.initialize();
  }
}
