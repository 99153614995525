import { Observable, Subject } from 'rxjs';
import { filter, map, first } from 'rxjs/operators';

/**
 * Hilfklasse zum Bündeln von Serverrequests
 */
export class RequestBundler<T> {
  /** Antwort des Serverrequests kombiniert mit der clientId und dem Bundle, mit denen der Request ausgeführt wurde */
  private response$ = new Subject<[number, Set<number>, T]>();
  /** Funktion, die den Serverrequest auslöst */
  private requestFn: RequestFn<T>;
  /** Bündel an Ids, mit denen der nächste Request ausgeführt werden soll nach Mandant gruppiert */
  private bundleMap: { [clientId: number]: Set<number> } = {};
  /** Ids, mit denen bereits ein Request ausgeführt wurden und auf eine Antwort gewartet wird nach Mandant gruppiert */
  private loadingMap: { [clientId: number]: Set<number> } = {};
  /** Referenz auf den Timeout, der den Serverrequest auslöst */
  private timeoutMap: { [clientId: number]: number } = {};
  /** Dauer, wann nach dem hinzufügen des ersten Elements zu einem Bündel der Request ausgeführt werden soll in ms */
  private timeout = 250;
  /** Maximale Größe eines Bündels, ab der der Reuest ungeachtet des Timeouts ausgelöst wird */
  private maxSize = 10;

  /**
   * @ignore
   */
  constructor(requestFn: RequestFn<T>) {
    this.requestFn = requestFn;
  }

  /**
   * Fügt eine Id zu einem Bündel hinzu
   *
   * @param clientId Id des Mandanten, für den der Request rausgehen soll
   * @param entityId Id, die zum Bündel hinzugefügt werden soll
   *
   * @return Observable, was einmal emittet, sobald der Request des Bündels mit der mitgegebenen Id durch ist
   */
  add(clientId: number, entityId: number): Observable<T> {
    if (!this.loadingMap[clientId] || !this.loadingMap[clientId].has(entityId)) {
      if (!this.bundleMap[clientId]) {
        this.bundleMap[clientId] = new Set<number>();
        this.timeoutMap[clientId] = window.setTimeout(() => this.sendRequest(clientId), this.timeout);
      }

      this.bundleMap[clientId].add(entityId);

      if (!this.loadingMap[clientId]) {
        this.loadingMap[clientId] = new Set<number>();
      }
      this.loadingMap[clientId].add(entityId);

      if (this.bundleMap[clientId].size >= this.maxSize) {
        clearTimeout(this.timeoutMap[clientId]);
        this.sendRequest(clientId);
      }
    }

    return this.response$.pipe(
      filter(([cId, idSet, _]) => cId === clientId && idSet.has(entityId)),
      first(),
      map(([cId, idSet, response]) => response),
    );
  }

  /**
   * Führt die mitgegebene Funktion zum senden eines Requests aus mit dem Aktuellen Bundle an Ids
   *
   * @param clientId Id des Mandanten, für den der Request rausgehen soll
   */
  private sendRequest(clientId: number): void {
    const idSet = this.bundleMap[clientId];
    const entityIds = Array.from(idSet);
    this.requestFn(clientId, entityIds).subscribe(
      (response) => {
        entityIds.forEach((id) => this.loadingMap[clientId].delete(id));
        this.response$.next([clientId, idSet, response]);
      },
      (error) => this.response$.error(error),
    );
    delete this.bundleMap[clientId];
  }
}

export type RequestFn<T> = (clientId: number, entityIds: number[]) => Observable<T>;
