import { AnimationEvent } from '@angular/animations';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../core/service/session.service';
import { AppStorageService } from '../core/statemanagement/app-storage.service';
import { HeaderParams } from '../core/statemanagement/state/application.state';
import { AnimationTrackerService } from '../core/service/animation-tracker.service';
import { CURTAIN_FROM_LEFT, CURTAIN_FROM_RIGHT, createPageAnimation } from '../core/utils/animation-utils';
import { SwUpdate } from '@angular/service-worker';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { INFO_DIALOG_SETTINGS, SMALL_DIALOG_SETTINGS } from '../core/utils/dialog-utils';
import { InfoDialComponent } from './modal/info-dial/info-dial.component';
import { SettingsDialComponent } from './modal/settings-dial/settings-dial.component';
import { NavigationService } from '../core/service/navigation.service';

@Component({
  selector: 'ca-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [createPageAnimation(CURTAIN_FROM_RIGHT, CURTAIN_FROM_LEFT)],
})
export class AppComponent implements OnInit {
  params$: Observable<HeaderParams>;

  private lastAnimationId: number;

  constructor(
    private swUpdate: SwUpdate,
    private appStorage: AppStorageService,
    private sessionService: SessionService,
    private dialogService: MatDialog,
    private translate: TranslateService,
    private animationTracker: AnimationTrackerService,
    private navService: NavigationService,
  ) {
    this.sessionService.initialize();
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.params$ = this.appStorage.selectHeaderParams();
  }

  back(): void {
    this.navService.back();
  }

  goHome(): void {
    this.navService.openDefaultFirstRoute();
  }

  prepRouteState(outlet: RouterOutlet): any {
    return outlet.activatedRouteData.page;
  }

  /**
   * Zeichnet das Start Event der Animation unseres RouterAnimation Triggers auf.
   * Das Event wird nur aufgezeichnet wenn fuer die fromState/toState Kombination
   * in unserem Trigger auch wirklich eine Transition definiert ist. Das erfahren
   * wir dadurch ob die totalTime > 0 ist.
   *
   * @param event - Das AnimationEvent
   */
  captureStartEvent(event: AnimationEvent): void {
    if (event.totalTime > 0) {
      this.lastAnimationId = this.animationTracker.captureStartEvent(event);
    }
  }

  /**
   * Zeichnet das Done Event der Animation unseres RouterAnimation Triggers auf.
   * Das Event wird nur aufgezeichnet wenn fuer die fromState/toState Kombination
   * in unserem Trigger auch wirklich eine Transition definiert ist. Das erfahren
   * wir dadurch ob die totalTime > 0 ist.
   *
   * @param event - Das AnimationEvent
   */
  captureDoneEvent(event: AnimationEvent): void {
    if (event.totalTime > 0) {
      this.animationTracker.captureDoneEvent(this.lastAnimationId, event);
    }
  }

  /**
   * Zum Suchen eines Updates der Anwendung über den ServiceWorker.
   */
  searchUpdates(): void {
    this.swUpdate.checkForUpdate();
  }

  /**
   * Öffnet einen Dialog mit dem Infotext der angezeigten Seite.
   *
   * @param title Der Titel des Info-Dialoges
   * @param content Der Inhalt des Info-Dialoges
   */
  openInfoDialog(title: string, content: string): void {
    this.dialogService.open(InfoDialComponent, {
      ...INFO_DIALOG_SETTINGS,
      data: {
        title: this.translate.instant(title),
        content: this.translate.instant(content),
      },
    });
  }

  /**
   * Dialog mit Einstellungsoptionen, Anzeige der Versionsnummer etc.
   */
  openSettings(): void {
    this.dialogService.open(SettingsDialComponent, {
      ...SMALL_DIALOG_SETTINGS,
    });
  }
}
