import { BvwClient } from 'src/app/module/client/service/client-backend.service';

export class SetClient {
  static readonly type = '[ClientState] set Client';
  constructor(public payload: number) {}
}

export class ClearClients {
  static readonly type = '[ClientState] clear Clients';
  constructor() {}
}

export class LoadBvwClient {
  static readonly type = '[ClientState] load BvwClient';
  constructor(public clientId: number) {}
}

export class SetBvwClientLoadFailed {
  static readonly type = '[ClientState] set BvwClientLoadFailed';
  constructor() {}
}

export class SetBvwClient {
  static readonly type = '[ClientState] set BvwClient';
  constructor(public payload: BvwClient) {}
}
