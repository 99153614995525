import { Injectable } from '@angular/core';
import { Permission } from './permission';

/**
 * Service-Stub, welcher die Implementierung für allgemeine Platformen enthält die nicht ANDROID sind.
 */
@Injectable({
  providedIn: 'root',
})
export class PermissionServiceStub implements Permission {
  constructor() {}

  verifyFilesystemRead(): Promise<void> {
    return Promise.resolve();
  }
}
