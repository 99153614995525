import { Injectable } from '@angular/core';
import { UsercacheService } from './usercache.service';
import { Observable, of } from 'rxjs';
import { User } from 'src/app/entity/user';
import { UserKey } from './user-backend.service';
import { map, tap } from 'rxjs/operators';

/** UserCache-Implementierung ohne Persistenz */
@Injectable({
  providedIn: 'root',
})
export class InMemoryUsercacheService implements UsercacheService {
  private cache: { [key: string]: User } = {};

  constructor() {}

  private toCacheKey(clientId: number, userId: number): string {
    return clientId + '-' + userId;
  }

  private cacheKeyOfUser(user: User): string {
    return this.toCacheKey(user.clientId, user.id);
  }

  findByKeys(clientId: number, ids: number[]): Observable<User[]> {
    return of(ids.map((id) => this.cache[this.toCacheKey(clientId, id)]).filter((entry) => !!entry));
  }

  findUnchangedByKeys(clientId: number, keys: UserKey[]): Observable<User[]> {
    return of(
      keys
        .map((key) => {
          const found = this.cache[this.toCacheKey(clientId, key.id)];
          if (null != found && key.lastChange === found.lastChange) {
            return found;
          }
          return null;
        })
        .filter((entry) => !!entry),
    );
  }

  putUser(user: User): Observable<User> {
    return this.putUsers([user]).pipe(map(([u]) => u));
  }

  putUsers(users: User[]): Observable<User[]> {
    return of(users).pipe(tap((u) => u.forEach((user) => (this.cache[this.cacheKeyOfUser(user)] = user))));
  }

  patchUser(clientId: number, user: User): Observable<User> {
    return this.patchUsers(clientId, [user]).pipe(map(([u]) => u));
  }

  patchUsers(clientId: number, users: User[]): Observable<User[]> {
    return of(users).pipe(
      map((u) => {
        return u.map((user) => {
          const patchedUser: User = { ...this.cache[this.cacheKeyOfUser(user)], ...user };
          this.cache[this.cacheKeyOfUser(user)] = patchedUser;
          return patchedUser;
        });
      }),
    );
  }

  deleteOther(clientId: number, userIds: number[]): Observable<void> {
    return of(userIds).pipe(
      map((uIds) => {
        const cacheKeys = uIds.map((id) => this.toCacheKey(clientId, id));
        Object.keys(this.cache).forEach((key) => {
          if (!cacheKeys.find((cacheKey) => key === cacheKey)) {
            delete this.cache[key];
          }
        });
      }),
    );
  }

  deleteUsers(clientId: number, userIds: number[]): Observable<void> {
    return of(userIds).pipe(
      map((uIds) => {
        uIds
          .map((id) => this.toCacheKey(clientId, id))
          .forEach((cacheKey) => {
            if (this.cache[cacheKey]) {
              delete this.cache[cacheKey];
            }
          });
      }),
    );
  }

  clearAll(): Observable<void> {
    return of({}).pipe(
      map(() => {
        this.cache = {};
      }),
    );
  }
}
