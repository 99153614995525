import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { combineLatest, from, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { LockService } from '../service/lock.service';
import { NavigationService } from '../service/navigation.service';
import { Session } from '../service/session.service';
import { AppStorageService } from '../statemanagement/app-storage.service';

/**
 * Ermöglicht automatisches Einloggen auf der Loginseite, wenn eine Session verfügbar ist
 */
@Injectable({
  providedIn: 'root',
})
export class AutologinGuard {
  constructor(
    private appStorage: AppStorageService,
    private navService: NavigationService,
    private translate: TranslateService,
    private lockService: LockService,
  ) {}

  /**
   * Navigiert auf die Standardseite, wenn eine Session vorhanden ist
   */
  canActivate(): Observable<boolean> {
    const title = this.translate.instant('LOCK.LOGIN_MSG');
    const touchId$ = from(
      this.lockService.verifyTouchID(title).then(
        (session) => {
          this.appStorage.setSession(session);
          return true;
        },
        () => false,
      ),
    );

    const hasSession$ = this.appStorage.selectSession().pipe(
      // Undefined ist der Anfangszustand
      filter((session) => session !== undefined),
      map((session: Session): boolean => !!session),
    );

    return combineLatest([hasSession$, touchId$]).pipe(
      // Nur wenn keine Session da ist, oder eine Session die nicht mit TouchId verifiziert wurde
      // geht's ab auf die Login Seite
      map(([hasSession, touchVerified]) => !hasSession || !touchVerified),
      tap((canActivate: boolean) => {
        if (!canActivate) {
          // Redirecten wir direkt in die Anwendung
          this.navService.openDefaultFirstRoute(true);
        }
      }),
    );
  }
}
