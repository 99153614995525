import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { InfoDialComponent } from '../modal/info-dial/info-dial.component';
import { INFO_DIALOG_SETTINGS } from 'src/app/core/utils/dialog-utils';

/**
 * Komponente zur Darstellung des Titels in einem Dialog
 */
@Component({
  selector: 'ca-dlg-header',
  templateUrl: './dlg-header.component.html',
  styleUrls: ['./dlg-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DlgHeaderComponent {
  /** Der Titel des Headers zur Anzeige im Dialog. */
  @Input() headerTitle: string;

  /** Der Titel des Infotext-Dialoges. */
  @Input() infoTextTitle: string;

  /** Der Inhalt des Infotext-Dialoges. */
  @Input() infoTextContent: string;

  /** Den Button zum Aufruf des Infotextes ausblenden */
  @Input() hideInfoButton: boolean;

  constructor(
    private dialogService: MatDialog,
    private translate: TranslateService,
  ) {}

  /**
   * Öffnet einen Dialog mit dem Infotext zum aktuell geöffneten Assistenten.
   */
  openInfoDialog(): void {
    this.dialogService.open(InfoDialComponent, {
      ...INFO_DIALOG_SETTINGS,
      data: {
        title: this.translate.instant(this.infoTextTitle),
        content: this.translate.instant(this.infoTextContent),
      },
    });
  }
}
