import { Injectable } from '@angular/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map, first, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { Session, Customer } from '../service/session.service';
import { SetClient, ClearClients } from './state/actions/client.action';
import { PatchHeaderParams } from './state/actions/header.action';
import { PatchSession, SetSession } from './state/actions/session.action';
import { ApplicationState, HeaderParams } from './state/application.state';
import { hasChanges } from '../utils/object-utils';

@Injectable({
  providedIn: 'root',
})
export class AppStorageService {
  constructor(
    private store: Store,
    private actions$: Actions,
  ) {
    // TODO eigentlich koennte man sich setClient sparen
    // und diese immer onDemand anlegen
    this.actions$
      .pipe(
        ofActionSuccessful(SetSession, PatchSession),
        filter((action) => !!action.payload),
        map((action) => action.payload.clientId),
        filter((clientId) => !!clientId),
      )
      .subscribe((clientId) => {
        this.setClient(clientId);
      });
  }

  selectSession(): Observable<Session> {
    return this.store.select(ApplicationState.selectSession);
  }

  selectHeaderParams(): Observable<HeaderParams> {
    return this.store.select(ApplicationState.selectHeaderParams);
  }

  setSession(session: Session): void {
    this.store.dispatch(new SetSession(session));
  }

  patchSession(session: Partial<Session>): void {
    this.store.dispatch(new PatchSession(session));
  }

  setClient(clientId: number): void {
    this.store.dispatch(new SetClient(clientId));
  }

  clearClients(): void {
    this.store.dispatch(new ClearClients());
  }

  setHeaderParams(params: HeaderParams): void {
    this.selectHeaderParams()
      .pipe(
        first(),
        filter((oldParams: HeaderParams): boolean => hasChanges(oldParams, params)),
        switchMap((): Observable<any> => this.store.dispatch(new PatchHeaderParams(params))),
      )
      .subscribe();
  }

  /** Gibt an, ob der angemeldete Benutzer ein Administrator ist
   * @return Observable<boolean>
   */
  isAdmin(): Observable<boolean> {
    return this.selectSession().pipe(
      map((session): boolean => {
        if (!session) {
          return null;
        }
        return session.identitiesMap[session.clientId].client.customer.admin;
      }),
    );
  }

  /** Gibt zurück, ob der angemeldete Benutzer das Recht admin oder userManager besitzt
   * @return Observable<boolean>
   */
  canManageUser(): Observable<boolean> {
    return this.selectSession().pipe(
      map((session) => {
        if (!session) {
          return null;
        }
        return (
          session.identitiesMap[session.clientId].client.customer.admin ||
          session.identitiesMap[session.clientId].client.customer.userManager
        );
      }),
      distinctUntilChanged(),
    );
  }

  /** Gibt an, ob der angemeldete Benutzer die Rechte admin, userManager oder freeInvite besitzt
   * @return Observable<boolean>
   */
  canManageUserOrInvite(): Observable<boolean> {
    return this.selectSession().pipe(
      map((session: Session): boolean => {
        if (!session) {
          return null;
        }
        const customer: Customer = session.identitiesMap[session.clientId].client.customer;
        return customer.admin || customer.userManager || customer.freeInvite;
      }),
      distinctUntilChanged(),
    );
  }

  /** Gibt zurück, ob der angemeldete Benutzer das Recht admin oder groupManager besitzt
   * @return Observable<boolean>
   */
  canManageGroups(): Observable<boolean> {
    return this.selectSession().pipe(
      map((session: Session): boolean => {
        if (!session) {
          return null;
        }
        const customer: Customer = session.identitiesMap[session.clientId].client.customer;
        return customer.admin || customer.groupManager;
      }),
      distinctUntilChanged(),
    );
  }

  /** Gibt zurück, ob der angemeldete Benutzer das Recht admin, userManager oder groupManager besitzt
   * @return Observable<boolean>
   */
  canManageUsersOrGroups(): Observable<boolean> {
    return this.selectSession().pipe(
      map((session: Session): boolean => {
        if (!session) {
          return null;
        }
        const customer: Customer = session.identitiesMap[session.clientId].client.customer;
        return customer.admin || customer.userManager || customer.groupManager;
      }),
      distinctUntilChanged(),
    );
  }

  /**
   * Gibt die ID des angemeldeten Benutzers zurück
   * oder null, wenn keine Session existiert
   * @return Observable<number>
   */
  currentLoggedInUserId(): Observable<number> {
    return this.selectSession().pipe(
      map((session: Session): number => {
        if (!session) {
          return null;
        }
        const customer: Customer = session.identitiesMap[session.clientId].client.customer;
        return customer.id;
      }),
      distinctUntilChanged(),
    );
  }
}
