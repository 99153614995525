import { GenericMap } from './utility-types';
import { User } from 'src/app/entity/user';
import { StateOperator } from '@ngxs/store';
import { UserGroup } from 'src/app/entity/usergroup';
import { UserStateModel } from '../statemanagement/state/user.state';
import { UsergroupStateModel } from '../statemanagement/state/usergroup.state';

/**
 * Setzt einen User
 * @param clientId Id des Mandanten
 * @param user Benutzer der gesetzt werden soll
 */
export function putUser(clientId: number, user: User): StateOperator<UserStateModel> {
  return (state: Readonly<UserStateModel>) => ({
    ...state,
    [clientId]: {
      ...state[clientId],
      users: {
        ...state[clientId].users,
        data: {
          ...state[clientId].users.data,
          [user.id]: user,
        },
      },
    },
  });
}

/**
 * Setzt mehrere User
 * @param clientId Id des Mandanten
 * @param users Benutzer die gesetzt werden sollen
 */
export function putUsers(clientId: number, users: GenericMap<User>): StateOperator<UserStateModel> {
  return (state: Readonly<UserStateModel>) => ({
    ...state,
    [clientId]: {
      ...state[clientId],
      users: {
        ...state[clientId].users,
        data: {
          ...state[clientId].users.data,
          ...users,
        },
      },
    },
  });
}

/**
 * Setzt sowohl die User data als auch die deletedUser data komplett neu
 * @param clientId Id des Mandanten
 * @param users Benutzer die gesetzt werden sollen
 * @param deletedUsers Gelöschte Benutzer die gesetzt werden sollen
 */
export function putUsersAndDeleted(
  clientId: number,
  users: GenericMap<User>,
  deletedUsers: GenericMap<User>,
): StateOperator<UserStateModel> {
  return (state: Readonly<UserStateModel>) => ({
    ...state,
    [clientId]: {
      ...state[clientId],
      users: {
        ...state[clientId].users,
        data: users,
      },
      deletedUsers: {
        ...state[clientId].deletedUsers,
        data: deletedUsers,
      },
    },
  });
}

/**
 * Setzt eine Gruppe
 * @param clientId Id des Mandanten
 * @param group Gruppe die gesetzt werden soll
 */
export function putGroup(clientId: number, group: UserGroup): StateOperator<UsergroupStateModel> {
  return (state: Readonly<UsergroupStateModel>) => ({
    ...state,
    [clientId]: {
      ...state[clientId],
      groups: {
        ...state[clientId].groups,
        data: {
          ...state[clientId].groups.data,
          [group.id]: group,
        },
      },
    },
  });
}

/**
 * Setzt mehrere Gruppen
 * @param clientId Id des Mandaten
 * @param groups Gruppen die gesetzt werden sollen
 */
export function putGroups(clientId: number, groups: GenericMap<UserGroup>): StateOperator<UsergroupStateModel> {
  return (state: Readonly<UsergroupStateModel>): UsergroupStateModel => ({
    ...state,
    [clientId]: {
      ...state[clientId],
      groups: {
        ...state[clientId].groups,
        data: {
          ...state[clientId].groups.data,
          ...groups,
        },
      },
    },
  });
}

/**
 * Patch die alle Daten unterhalb der ClientId
 * @param newData Die neuen Daten die gesetzt werden sollen
 * @param clientId ClientId des Mandanten
 */
export function patchClient<Model, StateModel>(newData: Partial<Model>, clientId: number): StateOperator<StateModel> {
  return (state: Readonly<StateModel>) => ({
    ...state,
    [clientId]: {
      ...state[clientId],
      ...newData,
    },
  });
}
