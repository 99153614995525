import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/entity/user';
import { UserKey } from './user-backend.service';

/**
 * Interface, welches von den Usercaches implementiert werden muss
 */
export interface UsercacheService {
  /**
   * Liefert User-Objekte zu den übergebenen Keys
   */
  findByKeys(clientId: number, ids: number[]): Observable<User[]>;

  /**
   * Liefert User-Objekte zu den übergebenen Keys welche sich nicht
   * geaendert haben, also das lastChanged Datum gleich geblieben ist.
   */
  findUnchangedByKeys(clientId: number, keys: UserKey[]): Observable<User[]>;

  /**
   * Übergebenen User in den Cache packen
   */
  putUser(user: Partial<User>): Observable<User>;

  /**
   * Übergebene User in den Cache packen
   */
  putUsers(users: Partial<User>[]): Observable<User[]>;

  /**
   * Übergebenen User in den Cache patchen
   */
  patchUser(clientId: number, user: Partial<User>): Observable<User>;

  /**
   * Übergebene User in den Cache patchen
   */
  patchUsers(clientId: number, users: Partial<User>[]): Observable<User[]>;

  /**
   * Alle User, die nicht übergeben werden, aus dem Cache löschen
   */
  deleteOther(clientId: number, usersIds: number[]): Observable<void>;

  /**
   * Alle Benutzer die übergeben wurden aus dem Cache löschen
   */
  deleteUsers(clientId: number, userIds: number[]): Observable<void>;

  /**
   * Den gesamten Cache leeren
   */
  clearAll(): Observable<void>;
}

export const USERCACHE_STORAGE = new InjectionToken<UsercacheService>('usercache.storage');
