import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AutologinGuard } from './core/guard/autologin.guard';
import { SessionCheckGuard } from './core/guard/session-check.guard';
import { LoginPageComponent } from './module/login/page/login/login-page.component';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: '',
    canActivate: !environment.production || environment.isCordova ? [AutologinGuard] : [],
    component: LoginPageComponent,
  },
  {
    path: 'clients/:clientId',
    canActivate: [SessionCheckGuard],
    loadChildren: () => import('./module/client/client.module').then((m) => m.ClientModule),
    data: { page: 'clientoverview' },
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
