import { MatDialogConfig } from '@angular/material/dialog';

export const DIALOG_SETTINGS: MatDialogConfig = {
  height: '85%',
  width: '80%',
  minWidth: '820px',
  minHeight: '650px',
  restoreFocus: false,
};

export const SMALL_DIALOG_SETTINGS: MatDialogConfig = {
  height: '40%',
  width: '50%',
  minWidth: '512px',
  minHeight: '270px',
  restoreFocus: false,
};

export const INFO_DIALOG_SETTINGS: MatDialogConfig = {
  width: '60%',
  minWidth: '615px',
  restoreFocus: false,
};
