import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Identity } from 'src/app/core/service/session.service';

@Component({
  selector: 'ca-identity-select',
  templateUrl: './identity-select.component.html',
  styleUrls: ['./identity-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdentitySelectComponent {
  constructor(
    private dialogRef: MatDialogRef<IdentitySelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IdentitySelectArgs,
  ) {}

  openIdentity(identity: Identity): void {
    this.dialogRef.close(identity);
  }
}

export interface IdentitySelectArgs {
  clientIds: number[];
  identityMap: { [n: string]: Identity };
}
