import { Injectable, Inject, NgZone } from '@angular/core';
import { fromEvent } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { EnvironmentService } from './environment.service';
import { DOCUMENT } from '@angular/common';
import { filter, map, first } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AppStorageService } from '../statemanagement/app-storage.service';
import { isPresent } from '../utils/fp-utils';
import { onZone } from '../utils/operator-utils';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private enviroService: EnvironmentService,
    private location: Location,
    private appStorageService: AppStorageService,
    private zone: NgZone,
    @Inject(DOCUMENT) private document: Document,
  ) {
    /**
     * Unter Android soll wenn der Backbutton geklickt wird zurück navigiert werden,
     * falls ein Dialog geöffnet ist soll dieser zunächst geschlossen werden
     */
    if (this.enviroService.isAndroid) {
      fromEvent(this.document, 'backbutton')
        .pipe(onZone(this.zone))
        .subscribe((ev) => {
          // Dialoge geöffnet -> obersten schließen
          const openDialogs = this.dialog.openDialogs;
          if (openDialogs && openDialogs.length > 0) {
            const highestDialog = openDialogs[openDialogs.length - 1];
            highestDialog.close();
          } else {
            // Kein Dialog geöffnet -> zurück navigieren
            this.back();
          }
        });
    }
  }

  /**
   * Navigiert zurück
   */
  back(): void {
    this.location.back();
  }

  /**
   * Navigiert auf die Startseite der Anwendung
   * @param replaceUrl Flag, ob die aktuelle URL ersetzt werden soll
   */
  openDefaultFirstRoute(replaceUrl: boolean = false): void {
    this.appStorageService
      .selectSession()
      .pipe(
        filter(isPresent),
        first(),
        map((session) => this.router.navigate(['clients', session.clientId], { replaceUrl })),
      )
      .subscribe();
  }
}
