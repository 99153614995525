import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileComponent } from './user-profile.component';
import { ItemsModule } from 'src/app/common-ui/items/items.module';
import { MaterialModule } from 'src/app/common-ui/material/material.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [UserProfileComponent],
  imports: [CommonModule, ItemsModule, MaterialModule, TranslateModule],
  exports: [UserProfileComponent],
})
export class UserProfileModule {}
