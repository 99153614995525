import { ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { LanguageService, Language } from 'src/app/core/service/language.service';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { FLAGS_SVG_ICON_SET } from '../icons/svg-sets/flags-svg-icon-set';

/**
 * Komponente zur Auswahl der Sprache
 */
@Component({
  selector: 'ca-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageComponent implements OnInit, OnDestroy {
  /** Die verfügbaren Sprachen */
  languages = this.langService.languages;
  /** Die aktuell eingestellte Sprache */
  selectedLang = this.langService.selectedLang;

  /** Kennzeichen, ob die vollständig Bezeichnung der Sprache angezeigt werden soll oder nur das Kürzel. */
  @Input() smallVariant = false;

  constructor(
    private langService: LanguageService,
    private translate: TranslateService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    iconRegistry.addSvgIconSetLiteralInNamespace('flags', sanitizer.bypassSecurityTrustHtml(FLAGS_SVG_ICON_SET));
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  /**
   * Führt eine Änderung der System-Sprache durch.
   * @param lang die gewählte Spracheinstellung
   */
  onLangChange(lang: Language): void {
    this.langService.changeLang(lang);
    this.translate.use(lang.code);
    this.selectedLang = lang;
  }

  /**
   * Ermittelt die anzuzeigende Bezeichnung der übergebenen Sprache, je nachdem, ob die Small-Variante (nur Kürzel) angezeigt wird oder nicht (vollständige Bezeichnung)
   *
   * @param lang die Sprache, deren Titel ermittelt werden soll
   */
  getLanguageTitle(lang: Language): string {
    if (this.smallVariant) {
      return this.translate.instant(lang.name);
    }

    return this.translate.instant(lang.fullName);
  }
}
