import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ca-imprint-dial',
  templateUrl: './imprint-dial.component.html',
  styleUrls: ['./imprint-dial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImprintDialComponent {
  constructor() {}
}
