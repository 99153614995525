import { InjectionToken } from '@angular/core';
import { LangDefinition } from '../service/language.service';
import { RequestBundler, RequestFn } from '../shared/RequestBundler';

export const WINDOW_REF = new InjectionToken<Window>('Window object');

export const RAWLANG_TOKEN = new InjectionToken<LangDefinition>('rawlang');

export const RequestBundlerFactory = new InjectionToken<(fn: RequestFn<any>) => RequestBundler<any>>(
  'RequestBundlerFactory',
);
