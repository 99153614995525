import { Provider } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TimeoutInterceptor } from './timeout.interceptor';

/**
 * Ein 'Barrel' mit Providern für alle Interceptoren in der Reihenfolge, in der diese ausgeführt werden sollen.
 *
 * Requests gehen von vorne nach hinten durch das Array, Responses von hinten nach vorne.
 *
 * Weitere Informationen zu Interceptors: https://angular.io/guide/http#http-interceptors
 */
export const httpInterceptors: Provider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TimeoutInterceptor,
    multi: true,
  },
];
