import { LangDefinition } from 'src/app/core/service/language.service';

export const RAWLANG: LangDefinition = {
  COMMON: {
    FILTER: {
      HEADER: {
        de: 'Filter',
        en: 'Filter',
      },
      SELECTED_FILTER: {
        de: 'Ausgewählte Filter:',
        en: 'Selected Filter:',
      },
      USER: {
        IS_INVITED: {
          de: 'Eingeladen',
          en: 'Invited',
        },
      },
      GROUP: {
        ACTIVE: {
          de: 'Aktiv',
          en: 'Active',
        },
        INACTIVE: {
          de: 'Inaktiv',
          en: 'Inactive',
        },
        MINE: {
          de: 'Meine Zuständigkeit',
          en: 'My responsibility',
        },
      },
    },
    SORT: {
      HEADER: {
        de: 'Sortierung',
        en: 'Sorting',
      },
      RESET: {
        de: 'Sortierung zurücksetzen',
        en: 'Reset Sorting',
      },
      DO: {
        de: 'Suchen',
        en: 'Search',
      },
    },
    LIST: {
      SELECTED: {
        de: 'ausgewählt',
        en: 'selected',
      },
      COUNT: {
        de: 'Gesamt',
        en: 'Total',
      },
    },
    LANGUAGE: {
      DE: {
        de: 'Deutsch',
        en: 'German',
      },
      EN: {
        de: 'Englisch',
        en: 'English',
      },
    },
  },
  DATE_FORMAT: {
    FULL: {
      de: 'dd.MM.yyyy HH:mm:ss',
      en: 'MM-dd-yyyy hh:mm:ss a',
    },
    DATE_TIME: {
      de: "dd.MM.yyyy HH:mm 'Uhr'",
      en: 'MM-dd-yyyy hh:mm a',
    },
    DATE: {
      de: 'dd.MM.yyyy',
      en: 'MM-dd-yyyy',
    },
  },
  LOGIN: {
    REGISTRATION: {
      de: 'Anmeldung',
      en: 'Login',
    },
    EMAIL: {
      de: 'E-Mail',
      en: 'E-Mail',
    },
    PASSWORD: {
      de: 'Kennwort',
      en: 'Password',
    },
    LOGIN: {
      de: 'Anmelden',
      en: 'Login',
    },
    FORGOT_PASSWORD: {
      de: 'Kennwort vergessen?',
      en: 'Forgot password?',
    },
    TERMS_OF_USE: {
      de: 'AGB',
      en: 'Terms of Use',
    },
    TERMS_OF_USE_TITLE: {
      de: 'Nutzungsbedingungen',
      en: 'Terms of Use',
    },
    PRIVACY: {
      de: 'Datenschutz',
      en: 'Privacy',
    },
    PRIVACY_TITLE: {
      de: 'Datenschutzerklärung',
      en: 'Privacy policy',
    },
    ABOUT: {
      de: 'Impressum',
      en: 'Imprint',
    },
    CLIENT_ADMIN_QUESTION: {
      de: 'Welchen Mandanten möchten Sie administrieren?',
      en: 'Which client do you want to administrate?',
    },
  },
  IMPRINT: {
    DESC: {
      de: 'Cocuun ist ein Kommunikationsdienst der',
      en: 'Cocuun is a communication service by',
    },
    HEAD_OFFICE: {
      de: 'Hauptsitz',
      en: 'Head Office',
    },
    PHONE: {
      de: 'Telefon',
      en: 'Phone',
    },
    FAX: {
      de: 'Telefax',
      en: 'Telefax',
    },
    BRANCH_OFFICE: {
      de: 'Niederlassung',
      en: 'Branch Office',
    },
    CEO: {
      de: 'Geschäftsführer',
      en: 'Chief Executives',
    },
    COURT: {
      de: 'Amtsgericht',
      en: 'District Court',
    },
    VAT: {
      de: 'Umsatzsteuer-Identifikationsnummer',
      en: 'Value Added Tax Identification Number',
    },
  },
  RESET_PWD_DIALOG: {
    de: 'Das Ändern Ihres Passwortes ist auf dieser Seite nicht möglich. Möchten Sie zur Startseite von Cocuun weitergeleitet werden, um dort Ihr Kennwort zu ändern?',
    en: 'Changing your passwort is not possible on this site. Do you want to be directed to the login page of Cocuun to reset your password?',
  },
  CONFIRM_DELETE: {
    TITLE: {
      de: 'Benutzer löschen',
      en: 'Delete user',
    },
    USERLIST_TITLE: {
      de: 'Folgende Benutzer sollen gelöscht werden:',
      en: 'The following users are about to be deleted:',
    },
    LASTADMIN_INFO: {
      de:
        'Die folgenden Benutzer sind in einem oder mehreren Gruppenordnern oder Gruppenchats die einzigen Administratoren. ' +
        'Falls Sie diese Benutzer löschen, werden die Gruppenordner/Gruppenchats geschlossen und können anschließend ' +
        'von den Teilnehmern nur noch gelesen werden.<br />' +
        'Das Schließen eines Gruppenordners/Gruppenchats kann nicht rückgängig gemacht werden!<br />' +
        'Falls Sie die betreffenden Gruppenordner/Gruppenchats noch benötigen, löschen Sie die Benutzer noch nicht. ' +
        'Bitten Sie sie zunächst neue Administratoren für die betreffenden Gruppenordner/Gruppenchats zu benennen.',
      en:
        'The following user are administrator in one or more group folders or chat groups. ' +
        'If you delete these users, these group folders/chat groups are being closed and are afterwards' +
        'read-only for all members.' +
        'Closing a group folder/chat group can not be undone!<br />' +
        'In case you might still need this group folder/chat group, do not delete the admin-user yet.' +
        'Ask him first to name a new administrator for this group folder/chat group.',
    },
    CONFIRMTEXT: {
      de: 'Bitte bestätigen Sie das Löschen der Benutzer mit ihrem Kennwort:',
      en: 'Please confirm this deletion by entering your password:',
    },
    PASSWORD: {
      de: 'Kennwort',
      en: 'Password',
    },
  },
  DIALOG: {
    CANCEL: {
      de: 'Abbrechen',
      en: 'Cancel',
    },
    DELETE: {
      de: 'Löschen',
      en: 'Delete',
    },
    OK: {
      de: 'OK',
      en: 'OK',
    },
    SAVE: {
      de: 'Speichern',
      en: 'Save',
    },
    YES: {
      de: 'Ja',
      en: 'Yes',
    },
    NO: {
      de: 'Nein',
      en: 'No',
    },
  },
  ERR: {
    LOGIN_FAILED: {
      de: 'Login fehlgeschlagen!',
      en: 'Login failed!',
    },
    LOGIN_DENIED: {
      de: 'Zugriff verweigert',
      en: 'Access denied',
    },
    NO_ADMIN_RIGHTS: {
      de: 'Sie besitzen nicht die erforderlichen Berechtigungen, um diese Seite aufzurufen.',
      en: 'You don’t have permission to access on this site.',
    },
    TEMPORARY_BANNED: {
      de: 'Ihr Konto wurde aufgrund von mehreren fehlgeschlagenen Anmeldeversuchen vorübergehend gesperrt.',
      en: 'Your account has been temporarily suspended due to multiple failed login attempts.',
    },
    NO_TOS_TOP: {
      de: 'Sie haben die Datenschutzerklärung noch nicht angenommen. Bitte öffnen Sie Cocuun und bestätigen Sie die aktuelle Datenschutzerklärung, um sich in der Administration anmelden zu können.',
      en: 'You have not accepted the terms of service. Please open Cocuun and accept the terms of service to log into the administration',
    },
    INVITE_FAILED: {
      de: 'Das Einladen ist fehlgeschlagen.',
      en: 'Invitation failed.',
    },
  },
  NAV: {
    CLIENT: {
      de: 'Mandant',
      en: 'Client',
    },
    USER: {
      de: 'Benutzer',
      en: 'Users',
    },
    MEMBER_IN: {
      de: 'Mitglied in ',
      en: 'Member in ',
    },
    USERGROUPS: {
      de: 'Benutzergruppen',
      en: 'Usergroups',
    },
  },
  RIGHTS: {
    ADMIN: {
      de: 'Administrator',
      en: 'Administrator',
    },
    CREATE_GROUPCHAT: {
      de: 'Darf Gruppenchats anlegen',
      en: 'Can Create Chat Groups',
    },
    CREATE_FOLDER: {
      de: 'Darf Gruppenordner anlegen',
      en: 'Can Create Group Folders',
    },
    FREE_INVITE: {
      de: 'Darf frei einladen',
      en: 'Can Freely Invite',
    },
    INTERN: {
      de: 'Stammbenutzer',
      en: 'Internal User',
    },
    PUBLIC_VISIBLE: {
      de: 'Für Stammbenutzer sichtbar',
      en: 'Visible for Internal Users',
    },
    GROUP_MANAGER: {
      de: 'Darf Benutzergruppen pflegen',
      en: 'Can Manage Usergroups',
    },
    USER_MANAGER: {
      de: 'Darf Benutzer pflegen',
      en: 'Can Manage Users',
    },
    USER_AND_GROUP_MANAGER: {
      de: 'Darf Benutzer und Benutzergruppen pflegen',
      en: 'Can Manage Users and Usergroups',
    },
    ADMIN_DESCRIPTION: {
      de: 'Ermöglicht die gesamte Pflege der Benutzer und Benutzergruppen in der Cocuun Administration.',
      en: 'Allows to maintain all users and usergroups in the Cocuun Administration.',
    },
    CREATE_GROUPCHAT_DESCRIPTION: {
      de: 'Berechtigt zum Anlegen von Gruppenchats im Namen des Mandanten innerhalb von Cocuun.',
      en: 'Allows to create chat groups on behalf of the client within Cocuun.',
    },
    CREATE_FOLDER_DESCRIPTION: {
      de: 'Berechtigt zum Anlegen von Gruppenordnern im Namen des Mandanten innerhalb von Cocuun.',
      en: 'Allows to create group folder on behal of the client within Cocuun.',
    },
    FREE_INVITE_DESCRIPTION: {
      de: 'Berechtigt das Einladen/Hinzufügen von neuen Benutzern zum Mandanten.',
      en: 'Allows to add and invite new users to the client.',
    },
    GROUP_MANAGER_DESCRIPTION: {
      de: 'Berechtigt zum Anlegen, Bearbeiten und Löschen von Benutzergruppen in der Cocuun Administration.',
      en: 'Allows to create, edit and delete usergroups in the Cocuun Administration.',
    },
    USER_MANAGER_DESCRIPTION: {
      de: 'Berechtigt zum Anlegen, Bearbeiten und Löschen von Benutzern in der Cocuun Administration.',
      en: 'Allows to create, edit and delete users in the Cocuun Administration.',
    },
    PUBLIC_VISIBLE_ADD_INFO: {
      de: '(Nicht änderbar, da der Benutzer Benutzergruppen zugeordnet ist.)',
      en: '(Cannot be changed because the user is assigned to user groups.)',
    },
    PUBLIC_VISIBLE_ADD_INFO_MULTI_USER: {
      de: '(Nicht änderbar, da mindestens einer der ausgewählten Benutzer Benutzergruppen zugeordnet ist.)',
      en: '(Cannot be changed because at least one of the selected users is assigned to user groups.)',
    },
    PASSWORD: {
      de: 'Kennwort',
      en: 'Password',
    },
    ENTER_PASSWORD: {
      de: 'Bitte geben Sie Ihr Kennwort ein',
      en: 'Please enter your password',
    },
    TITLE: {
      de: 'Rechte bearbeiten',
      en: 'Edit Rights',
    },
    NO_GROUPS: {
      de: 'ohne Benutzergruppen',
      en: 'without usergroups',
    },
    NO_USER: {
      de: 'ohne Benutzer',
      en: 'without user',
    },
    LAST_EDIT: {
      de: 'zuletzt bearbeitet',
      en: 'last modified',
    },
    WILL_BE_GIVEN: {
      de: '(wird erteilt)',
      en: '(will be given)',
    },
    WILL_BE_STRIPPED: {
      de: '(wird entzogen)',
      en: '(will be revoked)',
    },
    NOT_CHANGED: {
      de: '(bleibt unverändert)',
      en: '(will not change)',
    },
    SHOW_DELETED: {
      de: 'Gelöscht',
      en: 'Deleted',
    },
    ADMINISTRATIVE_PERMISION: {
      de: 'Welche Berechtigungen soll der Benutzer in der Cocuun Administration erhalten?',
      en: 'Which rights should the user get in the Cocuun Administration?',
    },
    STRUCTUREAL_PERMISSION_TITLE: {
      de: 'Welche Berechtigungen soll der Benutzer innerhalb von Cocuun erhalten?',
      en: 'Which rights should the user get in Cocuun?',
    },
  },
  SEARCHBAR: {
    FILTER_RESET: {
      de: 'Filter zurücksetzen',
      en: 'Reset Filter',
    },
    KEYWORDS: {
      de: 'Suchbegriffe: ',
      en: 'Search for: ',
    },
    FILTER_SET: {
      de: 'Anzeige filtern',
      en: 'Filter Overview',
    },
    TOOLTIP_EDITMODE_ON: {
      de: 'Bearbeitungsmodus einschalten',
      en: 'Activate Edit Mode',
    },
    TOOLTIP_EDITMODE_OFF: {
      de: 'Bearbeitungsmodus ausschalten',
      en: 'Deactivate Edit Mode',
    },
    EMPTY_LIST: {
      de: 'Keine Einträge vorhanden.',
      en: 'No entries available.',
    },
    NO_RESULTS: {
      de: 'Es wurden keine Einträge gefunden.',
      en: 'No entries have been found.',
    },
    SORT: {
      BY_NAME: {
        ASC: {
          de: 'Name (aufsteigend)',
          en: 'Name (ascending)',
        },
        DESC: {
          de: 'Name (absteigend)',
          en: 'Name (descending)',
        },
      },
      BY_ADD_INFO: {
        ASC: {
          de: 'Zusatzinformation (aufsteigend)',
          en: 'Additional Information (ascending)',
        },
        DESC: {
          de: 'Zusatzinformation (absteigend)',
          en: 'Additional Information (descending)',
        },
      },
      BY_JOIN_DATE: {
        ASC: {
          de: 'Erstellungsdatum (aufsteigend)',
          en: 'Creation Date (ascending)',
        },
        DESC: {
          de: 'Erstellungsdatum (absteigend)',
          en: 'Creation Date (descending)',
        },
      },
      BY_LAST_CHANGE: {
        ASC: {
          de: 'Änderungsdatum (aufsteigend)',
          en: 'Alteration Date (ascending)',
        },
        DESC: {
          de: 'Änderungsdatum (absteigend)',
          en: 'Alteration Date (descending)',
        },
      },
      BY_GROUP_NAME: {
        ASC: {
          de: 'Name (aufsteigend)',
          en: 'Name (ascending)',
        },
        DESC: {
          de: 'Name (absteigend)',
          en: 'Name (descending)',
        },
      },
      BY_GROUP_SIZE: {
        ASC: {
          de: 'Mitgliederanzahl (aufsteigend)',
          en: 'Number of Members (ascending)',
        },
        DESC: {
          de: 'Mitgliederanzahl (absteigend)',
          en: 'Number of Members (descending)',
        },
      },
      BY_CREATION_DATE: {
        ASC: {
          de: 'Erstellungsdatum (aufsteigend)',
          en: 'Creation Date (ascending)',
        },
        DESC: {
          de: 'Erstellungsdatum (absteigend)',
          en: 'Creation Date (descending)',
        },
      },
      BY_CHANGE_DATE: {
        ASC: {
          de: 'Änderungsdatum (aufsteigend)',
          en: 'Alteration Date (ascending)',
        },
        DESC: {
          de: 'Änderungsdatum (absteigend)',
          en: 'Alteration Date (descending)',
        },
      },
    },
  },
  USER_DETAIL: {
    TITLE: {
      de: 'Benutzer "{{username}}" bearbeiten',
      en: 'Edit User "{{username}}"',
    },
    INFO_TITLE: {
      de: 'Benutzer bearbeiten',
      en: 'Edit User',
    },
    ADDITIONAL_INFO: {
      de: 'Zusatzinformation (innerhalb der Administration)',
      en: 'Additional Information',
    },
    DELETEDINFO: {
      de: 'Der Benutzer wurde am {{date}} gelöscht',
      en: 'The User was deleted on {{date}}',
    },
    EMAIL: {
      de: 'E-Mail',
      en: 'E-Mail',
    },
    FIRSTNAME: {
      de: 'Vorname',
      en: 'First Name',
    },
    LASTNAME: {
      de: 'Nachname',
      en: 'Last Name',
    },
    PASSWORD_REQUIRED: {
      de:
        'Wenn Sie jemanden zu einem Administrator ernennen oder ein Administratorkonto ändern/erstellen möchten,' +
        ' müssen Sie aus Sicherheitsgründen ihr Kennwort angeben:',
      en: 'If you want to prommote someone to an administrator, you will have to supply your password for security reasons:',
    },
    AFFECTED_USERS: {
      de: 'Betroffene Benutzer:',
      en: 'Affected User:',
    },
    SSO_DATA: {
      de: 'Cocuun Konto',
      en: 'Cocuun Account',
    },
    DETAIL_INFO: {
      de: 'weitere Angaben',
      en: 'additional information',
    },
    CREATIONDATE: {
      de: 'angelegt am',
      en: 'created on',
    },
    LASTCHANGE: {
      de: 'letzte Änderung',
      en: 'last update',
    },
    DELETEDDATE: {
      de: 'gelöscht am',
      en: 'deleted on',
    },
    CREATOR: {
      de: 'angelegt von',
      en: 'created by',
    },
    INVITETOKEN: {
      de: 'Einladungscode',
      en: 'Invitation Token',
    },
    INVITETOKEN_SHARE: {
      de: 'Einladungscode teilen',
      en: 'Share Invitation Token',
    },
    INVITE: {
      de: 'Benutzer einladen',
      en: 'Invite User',
    },
    INVITE_NOW: {
      de: 'Den angelegten Benutzer direkt per E-Mail einladen',
      en: 'Invite the created user via e-mail now',
    },
    INVITED: {
      de: 'Eingeladen',
      en: 'Invited',
    },
    NOT_INVITED: {
      de: 'Nicht eingeladen',
      en: 'Not Invited',
    },
    NOW_INVITED_INFO: {
      de: 'Der Benutzer wurde erfolgreich eingeladen. Sie können den Einladungscode jetzt teilen.',
      en: 'The user has been invited successfully. You can now share the invitation token.',
    },
    INVITATION_SUCCESSFUL: {
      de: 'Der Benutzer wurde erfolgreich eingeladen.',
      en: 'Invitation successful.',
    },
    BASE_RECORD: {
      de: 'Angelegter Stammsatz/Einladung',
      en: 'Master Record/Invitation',
    },
    BASE_RECORD_NEW: {
      de: 'Stammsatz',
      en: 'Master Record',
    },
    COCUUN_ACCOUNT: {
      de: 'Cocuun Konto',
      en: 'Cocuun Account',
    },
    ACCOUNT_INFO: {
      de:
        'Über dieses Cocuun Konto hat der Teilnehmer die Einladung angenommen.' +
        ' Es wird in allen verknüpften Gruppenordnern, Gruppenchats, usw. angezeigt.',
      en: 'The member has accepted the invitation with this Cocuun account. It is visible in all connected group folders, chat groups, etc.',
    },
    INVITATION_INFO: {
      de: 'Die Einladung wurde noch nicht angenommen.',
      en: 'The invitation has not been accepted.',
    },
    NO_INVITATION_INFO: {
      de: 'Es wurde noch keine Einladung für den Benutzer versendet.',
      en: 'No invitation for this user sent.',
    },
    SAVE_SUCCESS: {
      de: 'Die Änderungen wurden erfolgreich gespeichert.',
      en: 'The changes have been successfully saved.',
    },
  },
  SHARE: {
    SUBJECT: {
      de: 'Neues Cocuun-Ticket für die Zusammenarbeit in Cocuun.',
      en: 'New Cocuun-Ticket for connecting via Cocuun.',
    },
    BODY: {
      de:
        '{{creator}} sendet Ihnen ein Cocuun-Ticket.\n\n' +
        'SCHRITT 1 - falls Sie noch nicht in Cocuun sind:\n\n' +
        'Installieren Sie einfach die mobile App oder Desktop-Version - und registrieren Sie sich dort.\n' +
        '• Download-Links (iOS, Android, Windows, macOS): www.cocuun.de/downloads\n' +
        '•Alternativ zu Cocuun im Web: www.cocuun.de/life\nCocuun ist für Sie kostenlos.\n\n' +
        'SCHRITT 2 - drin in Cocuun:\n\nNehmen Sie die Einladung zu "{{clientname}}" an, indem Sie über den "Plus"-Button auf ' +
        '"Einladung annehmen" gehen und dort folgenden Code eingeben:\n{{invitecode}}',
      en:
        '{{creator}} sends you a Cocuun-ticket.\n\n' +
        'STEP 1 - if you are not yet registered in Cocuun:\nSimply install the mobile app or desktop version - and get registered.\n\n' +
        '• Download-Links (iOS, Android, Windows, macOS): www.cocuun.de/downloads\n' +
        '• Alternative: use Cocuun on the Web: www.cocuun.de/life\n\nCocuun is free of charge for you.\n\n' +
        'STEP 2 - in Cocuun\n\nAccept the invitation to "{{clientname}}" by clicking on the "Plus"-button and select ' +
        '"Accept inivitation". Then you enter the following code:\n{{invitecode}}',
    },
  },
  BUTTON: {
    SAVE: {
      de: 'Speichern',
      en: 'Save',
    },
    CANCEL: {
      de: 'Abbrechen',
      en: 'Cancel',
    },
    BACK: {
      de: 'Zurück',
      en: 'Back',
    },
    ADD: {
      de: 'Neu',
      en: 'New',
    },
    DELETE: {
      de: 'Löschen',
      en: 'Delete',
    },
    TEXT: {
      SELECTED: {
        de: 'ausgewählt',
        en: 'selected',
      },
    },
  },
  CLIENT_VIEW: {
    TITLE: {
      de: 'Dashboard',
      en: 'Dashboard',
    },
    OPEN_INVITES: {
      de: 'Offene Einladungen',
      en: 'Open Invites',
    },
    LBL_DISKSPACE: {
      de: 'Speicherplatz',
      en: 'Diskspace',
    },
    LBL_SPACE_OF: {
      de: 'von',
      en: 'of',
    },
    LBL_FOLDER: {
      de: 'Gruppenordner',
      en: 'Group Folder',
    },
    LBL_POSTS: {
      de: 'Beiträge',
      en: 'Posts',
    },
    LBL_FILES: {
      de: 'Dateien',
      en: 'Files',
    },
    LBL_ADMIN_CNT: {
      de: 'Administratoren',
      en: 'Administrators',
    },
    LBL_INTERN_CNT: {
      de: 'Stammbenutzer',
      en: 'Internal Users',
    },
    LBL_LAST_EDITED: {
      de: 'Zuletzt bearbeitet:',
      en: 'Last Edited:',
    },
    LBL_USER: {
      de: 'Benutzer',
      en: 'Users',
    },
    LBL_INVITES_MORE: {
      de: 'weitere',
      en: 'more',
    },
    LBL_CLIENT_TITEL: {
      de: 'Mandanteninformationen',
      en: 'Client Information',
    },
    LBL_CLIENT_STATISTICS: {
      de: 'Statistik',
      en: 'Statistics',
    },
    LBL_CLIENT_DIALOG_TITLE: {
      de: 'Info',
      en: 'Info',
    },
    LBL_CLIENT_DIALOG_TEXT: {
      de: 'Diese Informationen können aktuell nur vom Cocuun Team verändert werden. Bitte wenden Sie sich bei Änderungswünschen über das Cocuun Service Center an den Support.',
      en: 'This information can currently only be changed by the Cocuun team. If you have any change requests, please contact the support via the Cocuun Service Center.',
    },
    LBL_NO_GROUPS: {
      de: 'Es wurden noch keine Benutzergruppen angelegt.',
      en: 'No usergroups created, yet.',
    },
    LBL_GROUP_ACTIVE: {
      de: 'Aktive',
      en: 'Active',
    },
    LBL_GROUP_MINE: {
      de: 'Meine',
      en: 'Mine',
    },
  },
  EDIT_GROUP: {
    SAVE_SUCCESS: {
      de: 'Die Änderungen wurden erfolgreich gespeichert.',
      en: 'The changes have been successfully saved.',
    },
    EDIT_INFORMATION: {
      de: 'Informationen anpassen',
      en: 'Edit Information',
    },
    HEADER_GROUP_INFORMATION: {
      de: 'Welche Informationen möchten Sie anpassen?',
      en: 'Which information do you want to change?',
    },
    ADD_USER: {
      de: 'Wen möchten Sie neu hinzufügen?',
      en: 'Who do you want to add?',
    },
    DELETE_USER: {
      de: 'Wen möchten Sie entfernen?',
      en: 'Who do you want to remove?',
    },
  },
  EDIT_GROUPS: {
    TITLE: {
      de: 'Benutzergruppen von {{firstname}} {{lastname}} bearbeiten',
      en: 'Edit usergroups of {{firstname}} {{lastname}}',
    },
    ADD: {
      de: 'Benutzergruppe(n) hinzufügen',
      en: 'Add Usergroup(s)',
    },
    REMOVE: {
      de: 'Angezeigte Benutzergruppen entfernen',
      en: 'Remove Shown Usergroups',
    },
    BACK: {
      de: 'Übersicht',
      en: 'Overview',
    },
  },
  OVERVIEW: {
    NO_GROUPS: {
      de: 'Der Benutzer wurde keinen Benutzergruppen zugeordnet.',
      en: 'This user was not assigned to any usergroup.',
    },
    NO_MEMBERS: {
      de: 'Dieser Benutzergruppe wurden keine Mitglieder hinzugefügt.',
      en: 'No members have been added to this usergroup.',
    },
  },
  USERGROUP: {
    ADD: {
      de: 'Benutzergruppe anlegen',
      en: 'Create a Usergroup',
    },
    GROUPINFO: {
      de: 'Benutzergruppen',
      en: 'Usergroups',
    },
    MEMBER: {
      de: 'Mitglieder',
      en: 'Members',
    },
    EDIT: {
      de: 'Benutzergruppe bearbeiten',
      en: 'Edit Usergroup',
    },
    DELETE: {
      de: 'Benutzergruppe löschen',
      en: 'Delete Usergroup',
    },
    COMPARE: {
      de: 'Benutzergruppe vergleichen',
      en: 'Compare Usergroup',
    },
    ASSIGNED_GROUPS: {
      de: 'Zugeordnete Benutzer: ',
      en: 'Assigned Users: ',
    },
    ACTIVATE: {
      de: 'Benutzergruppe aktivieren',
      en: 'Activate Usergroup',
    },
    DEACTIVATE: {
      de: 'Benutzergruppe deaktivieren',
      en: 'Deactivate Usergroup',
    },
  },
  EDIT_MEMBERS: {
    TITLE: {
      de: 'Mitglieder der Benutzergruppe "{{name}}"',
      en: 'Members of Usergroup "{{name}}"',
    },
    ADD: {
      de: 'Benutzer hinzufügen',
      en: 'Add User',
    },
    REMOVE: {
      de: 'Mitglieder der Benutzergruppe entfernen',
      en: 'Remove Shown Members',
    },
  },
  DELETE_USER: {
    STEPPER: {
      SUMMARY: {
        de: 'Zusammenfassung',
        en: 'Summary',
      },
      CONFIRM: {
        de: 'Löschen bestätigen',
        en: 'Confirm Deletion',
      },
    },
    HEADER: {
      SINGLE: {
        de: 'Benutzer "{{username}}" löschen',
        en: 'Delete User "{{username}}"',
      },
      MULTIPLE: {
        de: 'Mehrere Benutzer löschen',
        en: 'Delete Multiple Users',
      },
    },
    INFO_TITLE: {
      SINGLE: {
        de: 'Benutzer löschen',
        en: 'Delete User',
      },
      MULTIPLE: {
        de: 'Mehrere Benutzer löschen',
        en: 'Delete Multiple Users',
      },
    },
    INFO_SUMMARY_ONE: {
      de: 'Sind Sie sicher, dass dieser Benutzer gelöscht werden soll?',
      en: 'Are you sure that this user should be deleted?',
    },
    INFO_SUMMARY_MANY: {
      de: 'Sind Sie sicher, dass die folgenden {{counter}} Benutzer gelöscht werden sollen?',
      en: 'Are you sure that the following {{counter}} users should be deleted?',
    },
    INFOTEXT: {
      de: 'Bitte bestätigen Sie das Löschen mit der Eingabe ihres Kennwortes.',
      en: 'Please confirm the deletion by entering the password.',
    },
    NO_REVERSE: {
      de: 'Das Löschen kann nicht rückgängig gemacht werden!',
      en: 'The deletion can not be undone!',
    },
    WILL_DELETE: {
      de: '(wird gelöscht)',
      en: '(will be deleted)',
    },
    DELETE_INFO_MANY: {
      de:
        'Die ausgewählten Benutzer werden aus allen Gruppenordnern und ' +
        'Gruppenchats des Mandanten entfernt und aus dem Mandanten gelöscht.',
      en: 'The selected user will be deleted from all group folders and chat groups of the client in Cocuun and will be removed from the client.',
    },
    DELETE_INFO_ONE: {
      de:
        'Der ausgewählte Benutzer wird aus allen Gruppenordnern und ' +
        'Gruppenchats des Mandanten entfernt und aus dem Mandanten gelöscht.',
      en: 'The selected user will be removed from all group folders and chat groups of the client in Cocuun and will be removed from the client.',
    },
    DELETE_SUCCESS: {
      de: 'Der Benutzer wurde erfolgreich gelöscht.',
      en: 'User successfully deleted.',
    },
  },
  EDIT_USER: {
    HEADER: {
      de: '{{firstname}} {{lastname}}',
      en: '{{firstname}} {{lastname}}',
    },
    EDIT_MULTI: {
      de: 'Ausgewählte Benutzer',
      en: 'Selected Users',
    },
    HEADER_MULTIPLE_MEMBERS: {
      de: 'Mehrere Benutzer bearbeiten',
      en: 'Edit Multiple Users',
    },
    EDIT_INFORMATION: {
      de: 'Informationen anpassen',
      en: 'Edit Information',
    },
    HEADER_MULTI_USER_INFO: {
      de: 'Sollen die Daten der Benutzer angeglichen werden?',
      en: 'Should the user data be aligned?',
    },
    EDIT_MULTI_ADDINFO: {
      de: 'Zusatzinformation aller ausgewählten Benutzer vereinheitlichen',
      en: 'Unify the additional information of all selected users',
    },
    INFO_SUMMARY: {
      de: 'Die folgenden {{counter}} Benutzer wurden zum Bearbeiten ausgewählt.',
      en: 'The following {{counter}} users have been selected for editing.',
    },
    SELECTABLE_HEADER: {
      de: 'meine Zuständigkeit ({{amount}})',
      en: 'my responsibility ({{amount}})',
    },
    UNSELECTABLE_HEADER: {
      de: 'nicht meine Zuständigkeit ({{amount}})',
      en: 'not my responsibility ({{amount}})',
    },
    CLICK_UNSELECTABLE_DIALOG_TITLE: {
      de: 'Info',
      en: 'Info',
    },
    CLICK_UNSELECTABLE_DIALOG_BODY: {
      de:
        'Sie können die Zuordnung zu dieser Benutzergruppe nicht entfernen, da Sie nicht für diese zuständig sind. ' +
        'Bitte wenden Sie sich an den zuständigen Administrator dieser Benutzergruppe.',
      en:
        "You can't change membership, because you are not responsible for this group. " +
        'Please ask a manager of the group.',
    },
  },
  ADD_USER: {
    HEADER: {
      de: 'Neuen Benutzer anlegen',
      en: 'Create a New User',
    },
    HEADER_MULTIPLE_MEMBERS: {
      de: 'Mehrere Benutzer bearbeiten',
      en: 'Edit Multiple Users',
    },
    USER_INFORMATION: {
      de: 'Informationen erfassen',
      en: 'Add Information',
    },
    HEADER_USER_INFORMATION: {
      de: 'Bitte geben Sie die Daten des neuen Benutzers ein.',
      en: 'Please insert the data of the new user.',
    },
    HEADER_RIGHTS: {
      de: 'Rechte setzen',
      en: 'Set Rights',
    },
    ADD_TO_GROUPS: {
      de: 'Benutzergruppen zuordnen',
      en: 'Assign to Usergroups',
    },
    ADD_TO_GROUPS_INFO: {
      de: 'Welchen Benutzergruppen soll der neue Benutzer zugeordnet werden?',
      en: 'To which group do you want to assign the new user?',
    },
    OVERVIEW: {
      de: 'Zusammenfassung',
      en: 'Overview',
    },
    CONFIRM_ADMIN: {
      de: 'Recht zur Administration bestätigen',
      en: 'Confirm Right to Admin',
    },
    CONFIRM_ADMIN_INFO: {
      de: 'Bitte bestätigen Sie die Änderung mit ihrem Kennwort.',
      en: 'Please confirm the change with your password.',
    },
    HEADER_ADD_USERGROUPS: {
      de: 'Neue Benutzergruppen hinzufügen',
      en: 'Add New Usergroups',
    },
    HEADER_REMOVE_USERGROUPS: {
      de: 'Zugeordnete Benutzergruppen entfernen',
      en: 'Remove Assigned Usergroups',
    },
    CREATE_SUCCESS: {
      de: 'Der Benutzer wurde erfolgreich angelegt.',
      en: 'User successfully created.',
    },
  },
  ADD_GROUP: {
    HEADER: {
      de: 'Neue Benutzergruppe anlegen',
      en: 'Create a New Usergroup',
    },
    GROUP_INFORMATION: {
      de: 'Informationen erfassen',
      en: 'Add Information',
    },
    HEADER_GROUP_INFORMATION: {
      de: 'Bitte geben Sie die Daten der neuen Benutzergruppe ein.',
      en: 'Please insert the data of the new group.',
    },
    USERS: {
      de: 'Benutzer zuordnen',
      en: 'Assign Users',
    },
    EDIT_USER: {
      de: 'Mitglieder bearbeiten',
      en: 'Edit Members',
    },
    ADD_USERS: {
      de: 'Benutzer hinzufügen',
      en: 'Add User',
    },
    ADD_USERS_INFO: {
      de: 'Welche Benutzer sollen der Benutzergruppe hinzugefügt werden?',
      en: 'Which users should be added to this group?',
    },
    SET_RESPONSIBLE: {
      de: 'Zuständigkeit festlegen',
      en: 'Set Responsiblity',
    },
    SET_RESPONSIBLE_INFO: {
      de: 'Wer soll diese Benutzergruppe pflegen?',
      en: 'Who should manage this group?',
    },
    OVERVIEW: {
      de: 'Zusammenfassung',
      en: 'Overview',
    },
    CREATION_SUCCESSFUL: {
      de: 'Die Benutzergruppe wurde erfolgreich angelegt.',
      en: 'Creation of the usergroup successful.',
    },
  },
  CUSTOMER_INFO: {
    HEADER: {
      de: 'Wollen Sie die Zusatzinformationen des Benutzers anpassen?',
      en: 'Do you want to change the additional informations of the user?',
    },
    HEADER_MULTI_USER: {
      de: 'Wollen Sie die Zusatzinformationen der Benutzer anpassen?',
      en: 'Do you want to change the additional informations of the users?',
    },
    CREATE: {
      de: 'Zusatzinformationen erfassen',
      en: 'Add Additional Information',
    },
    EDIT: {
      de: 'Zusatzinformationen pflegen',
      en: 'Maintain Additional Information',
    },
    INFO_ADMIN: {
      de: 'Für Administratoren',
      en: 'For Administrators',
    },
    INFO_ALL: {
      de: 'Für alle Gruppenmitglieder',
      en: 'For All Group Members',
    },
    INFOS_AVAILABLE: {
      de: '(mehrere Werte vorhanden)',
      en: '(multiple infomations available)',
    },
  },
  COMPARE_GROUP: {
    HEADER: {
      de: 'Zwei Benutzergruppen vergleichen',
      en: 'Compare Two Usergroups',
    },
    CHOOSE_GROUPS: {
      de: 'Mit welcher Benutzergruppe möchten Sie vergleichen?',
      en: 'Which user group do you want to compare with?',
    },
    CHOOSE_GROUPS_HEADER: {
      de: 'Vergleich auswählen',
      en: 'Choose Comparison',
    },
    EDIT_MEMBERS: {
      de: 'Mitglieder bearbeiten',
      en: 'Edit Members',
    },
    OVERVIEW: {
      de: 'Zusammenfassung',
      en: 'Overview',
    },
    COMMON_MEMBERS: {
      de: 'Gemeinsame Mitglieder',
      en: 'Common Members',
    },
    VARIOUS_MEMBERS: {
      de: 'Verschiedene Mitglieder',
      en: 'Various Members',
    },
    SAVE_SUCCESS: {
      de: 'Die Änderungen wurden erfolgreich gespeichert.',
      en: 'The changes have been successfully saved.',
    },
    IN_BOTH_GROUPS: {
      de: 'in beiden Benutzergruppen ({{amount}})',
      en: 'in both groups ({{amount}})',
    },
    NOT_IN_BOTH_GROUPS: {
      de: 'nicht in beiden Benutzergruppen ({{amount}})',
      en: 'not in both groups ({{amount}})',
    },
    NOT_RESPONSIBLE: {
      de: '(nicht zuständig)',
      en: '(not responsible)',
    },
  },
  DELETE_GROUP: {
    HEADER: {
      SINGLE: {
        de: 'Benutzergruppe "{{groupname}}" löschen',
        en: 'Delete Usergroup "{{groupname}}"',
      },
    },
    INFO_TITLE: {
      SINGLE: {
        de: 'Benutzergruppe löschen',
        en: 'Delete Usergroup',
      },
    },
    OVERVIEW: {
      de: 'Ausgewählte Benutzergruppe',
      en: 'Selected Usergroup',
    },
    OVERVIEW_PLURAL: {
      de: 'Ausgewählte Benutzergruppen',
      en: 'Selected Usergroups',
    },
    FOLLOWING_WILL_DELETED: {
      de: 'Sind Sie sicher, dass diese Benutzergruppe gelöscht werden soll?',
      en: 'Are you sure you want to delete this group?',
    },
    REMOVE_USERS: {
      de: 'Benutzer entfernen',
      en: 'Remove Users',
    },
    MEMBER_IN_THIS_GROUP: {
      de: 'Welche Mitglieder dieser Benutzergruppe möchten Sie <b>aus dem Mandanten</b> entfernen?',
      en: 'Which members of this user group do you want to remove <b>from the client</b>?',
    },
    NO_USER_ASSIGNED_TO_GROUP: {
      de: 'Kein Benutzer ist dieser Gruppe zugeordnet.',
      en: 'No user is assigned to this group.',
    },
    NO_USER_ASSIGNED_TO_GROUPS: {
      de: 'Keine Benutzer sind diesen Gruppen zugeordnet.',
      en: 'No users are assigned to the groups.',
    },
    ONLY_IN_THIS_GROUP: {
      de: 'nur in dieser Benutzergruppe ({{amount}})',
      en: 'only in this group ({{amount}})',
    },
    NOT_ONLY_IN_THIS_GROUP: {
      de: 'nicht nur in dieser Benutzergruppe ({{amount}})',
      en: 'not only in this group ({{amount}})',
    },
    SUMMARY: {
      de: 'Zusammenfassung',
      en: 'Overview',
    },
    GROUP_WILL_DELETED: {
      de: 'Die Benutzergruppe "{{groupname}}" wird gelöscht.',
      en: "The usergroup '{{groupname}}' will be deleted.",
    },
    ADDITIONAL_USER_DELETED: {
      de: 'Sind Sie sicher, dass auch folgende Benutzer aus dem Mandanten entfernt werden sollen?',
      en: 'Are you sure that the following users should be removed from the client, too?',
    },
    CONFIRM_WITH_PASSWORD: {
      de: 'Bitte bestätigen Sie das Löschen der Benutzer mit Ihrem Kennwort.',
      en: 'Please confirm with your password to delete the user.',
    },
    CONFIRM_DELETE: {
      de: 'Löschen bestätigen',
      en: 'Confirm Deletion',
    },
    CONFIRM_DELETED_GROUPS: {
      de: 'Folgende Benutzergruppen werden unwiderruflich gelöscht:',
      en: 'The following usergroups will be deleted permanently:',
    },
    GROUP_DELETED: {
      de: 'Die Benutzergruppe wurde erfolgreich gelöscht.',
      en: 'Usergroup successfully deleted.',
    },
    GROUP_WITH_USER_DELETED: {
      de: 'Die Benutzergruppe und die ausgewählten Benutzer wurden gelöscht.',
      en: 'The usergroup and the selected user were deleted.',
    },
    NO_DELETED_USERS: {
      de: 'Es werden <b>keine</b> Benutzer aus dem Mandanten entfernt.',
      en: 'There will be <b>no</b> users removed from the client.',
    },
    CANNOT_DELETE_USER_HEADER: {
      de: 'Info',
      en: 'Info',
    },
    CANNOT_DELETE_USER: {
      de: 'Dieser Benutzer ist Mitglied in weiteren Benutzergruppen. Daher kann er nur direkt im Bereich "Benutzer" aus dem Mandanten entfernt werden.',
      en: 'This user is a member of other usergroups. Therefore, he can only be removed from the client directly in the "Users" section.',
    },
    DELETE_NOT_POSSIBLE: {
      de: 'Löschen der Benutzergruppe nicht möglich',
      en: 'Cannot Delete the Usergroup',
    },
    CANNOT_DELETE_GROUP: {
      de:
        'Die Benutzergruppe "{{groupname}}" kann nicht gelöscht werden, da sie in Cocuun Profilen zugeordnet ist. Entfernen Sie zunächst in Cocuun diese Zuordnung, um die Benutzergruppe dann löschen zu können. ' +
        'Wollen Sie die Benutzergruppe stattdessen deaktivieren? Dann steht sie nicht mehr für eine neue Zuordnung in Cocuun zur Verfügung.',
      en:
        'The usergroup "{{groupname}}" cannot be deleted, because it is assigned to profiles in Cocuun. Please remove all assignments to delete the usergroup. ' +
        'Do you want to deactivate the usergroup instead? If you deactivate the usergroup, it remains in the assigned group folders and chat groups in Cocuun but cannot be assigned to new group folders or chat groups.',
    },
    CANNOT_DELETE_AND_DEACTIVATE_GROUP: {
      de: 'Die Benutzergruppe "{{groupname}}" kann nicht gelöscht werden, da sie in Cocuun Profilen zugeordnet ist. Entfernen Sie zunächst in Cocuun diese Zuordnung, um die Benutzergruppe dann löschen zu können.',
      en: 'The usergroup "{{groupname}}" cannot be deleted, because it is assigned to profiles in Cocuun. Please remove all assignments to delete the usergroup.',
    },
    DEACTIVATE_INSTEAD: {
      de: 'Wollen Sie die Benutzergruppe stattdessen deaktivieren?',
      en: 'Do you want to deactivate the usergroup instead?',
    },
  },
  NEXT_BUTTON: {
    DEFAULT: {
      de: 'Weiter',
      en: 'Next',
    },
  },
  FINISH_BUTTON: {
    DEFAULT: {
      de: 'Fertig',
      en: 'Finish',
    },
    DELETE: {
      de: 'Löschen',
      en: 'Delete',
    },
    CLOSE: {
      de: 'Schließen',
      en: 'Close',
    },
  },
  BACK: {
    DEFAULT: {
      de: 'Zurück',
      en: 'Back',
    },
    LOGOUT: {
      de: 'Abmelden',
      en: 'Logout',
    },
    TO_OVERVIEW: {
      de: 'Zurück zur Übersicht',
      en: 'Back to Overview',
    },
    TO_USERGROUPS: {
      de: 'Zurück zu Benutzergruppen',
      en: 'Back to Usergroups',
    },
    TO_USERGROUP: {
      de: 'Zurück zur Benutzergruppe',
      en: 'Back to Usergroup',
    },
    TO_USERS: {
      de: 'Zurück zu Benutzer',
      en: 'Back to Users',
    },
    TO_USER: {
      de: 'Zurück zum Benutzer',
      en: 'Back to User',
    },
  },
  EXTEND_INFO: {
    COCUUN_ID: {
      de: 'Cocuun-ID: {{id}}',
      en: 'Cocuun-ID: {{id}}',
    },
    MEMBER_COUNT_SINGLE: {
      de: '{{number}} Mitglied',
      en: '{{number}} Member',
    },
    MEMBER_COUNT_PLURAL: {
      de: '{{number}} Mitglieder',
      en: '{{number}} Members',
    },
    INVITE_CODE: {
      de: 'Einladungscode: {{code}}',
      en: 'Invitation Token: {{code}}',
    },
  },
  LOADING: {
    RETRY: {
      de: 'Erneut versuchen',
      en: 'Retry',
    },
    LOADING_DATA: {
      de: 'Lade Daten ...',
      en: 'Loading Data ...',
    },
    LOADING_FAILED: {
      de: 'Fehler beim Laden der Daten!',
      en: 'Failed to load data!',
    },
  },
  WARNING: {
    REMOVE_OWN_RIGHTS_TITLE: {
      de: 'Eigene Rechte bearbeiten',
      en: 'Edit Own Rights',
    },
    REMOVE_OWN_RIGHTS_BODY: {
      de: 'Sie sind dabei, ihre eigenen Rechte zu bearbeiten. Daher werden Sie nach dem Speichern der Änderungen automatisch abgemeldet.',
      en: 'You are aim to edit your own rights. Therefore you will be logged out after saving the changes.',
    },
  },
  ERROR: {
    PRECOLON: {
      de: 'Fehler:',
      en: 'Error:',
    },
    TITLE: {
      de: 'Es ist ein Fehler aufgetreten',
      en: 'An error occured',
    },
    TOO_LONG: {
      de: 'Sie haben die maximale Zeichenlänge überschritten.',
      en: 'You have exceeded the maximum character length.',
    },
    INVALID_EMAIL: {
      de: 'Bitte geben Sie eine gültige E-Mail Adresse ein.',
      en: 'Please enter a valid e-mail address',
    },
    TOO_SHORT: {
      de: 'Sie müssen mindestens zwei Zeichen eingeben.',
      en: 'You must enter at least two characters.',
    },
    TOO_SHORT_FIVE_CHARS: {
      de: 'Sie müssen mindestens fünf Zeichen eingeben.',
      en: 'You must enter at least five characters.',
    },
    TOO_MANY_TRIES: {
      de: 'Die Aktion wurde aufgrund zuvieler Fehlversuche vorübergehend gesperrt. Bitte versuchen Sie es später noch einmal.',
      en: 'The action was temporarily suspended due to too many failed attempts. Please try again later.',
    },
    REQUIRED: {
      de: 'Bitte füllen Sie dieses Feld aus.',
      en: 'Please fill in this field.',
    },
    GENERIC: {
      de: 'Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut.',
      en: 'An Error has ocurred, please try again later.',
    },
    NO_PERMISSIONS: {
      de: 'Die von der Anwendung benötigten Berechtigungen wurden nicht erteilt oder entzogen. Bitte überprüfen Sie ihre Einstellungen.',
      en: 'The permissions required by the application have not been granted or revoked. Please check your settings.',
    },
    WRONG_PASSWORD: {
      de: 'Falsches Kennwort',
      en: 'Wrong Password',
    },
    WRONG_ADMIN_PASSWORD: {
      de: 'Das eingegebene Kennwort ist falsch.',
      en: 'The entered password is wrong.',
    },
    EMAIL_ALREADY_USED: {
      de:
        'Die von Ihnen verwendete E-Mail Adresse ist bereits vergeben. ' +
        'Bitte wählen Sie eine alternative E-Mail Adresse zur Neuanlage aus.',
      en: 'The entered mail adress is already in use. Please use an alternative mail adress.',
    },
    EMAIL_ALREADY_USED_TITLE: {
      de: 'E-Mail Adresse bereits vergeben',
      en: 'E-Mail already in use',
    },
    OFFLINE: {
      de: 'Die Aktion kann offline nicht durchgeführt werden.',
      en: 'Unavailable when offline.',
    },
    OFFLINE_TITLE: {
      de: 'Offline',
      en: 'Offline',
    },
    CONFLICT_TITLE: {
      de: 'Konflikt aufgetreten',
      en: 'Conflict Occured',
    },
    CONFLICT_GROUP_BODY: {
      de: 'Änderungen können nicht übernommen werden, weil die Gruppe zwischenzeitlich bearbeitet wurde.',
      en: "Couldn't save the changes because the group was edited in between",
    },
    CONFLICT_USER_BODY: {
      de: 'Änderungen können nicht übernommen werden, weil der Benutzer zwischenzeitlich bearbeitet wurde.',
      en: "Couldn't save the changes because the user was edited in between",
    },
    MAIL_FAILED_TITLE: {
      de: 'Fehler beim Versenden von Einladungen',
      en: 'Sending Invitation Error',
    },
    MAIL_FAILED_BODY: {
      de: 'An folgende E-Mailadressen konnte keine Einladung gesendet werden: {{names}}',
      en: 'Failed to send invites to following addresses: {{names}}',
    },
    GROUPMANAGER_MUST_REMAIN: {
      de: 'Der Benutzer kann nicht gelöscht werden, da er alleine für eine Benutzergruppe zuständig ist. Für jede Benutzergruppe muss mindestens eine Person zuständig sein.',
      en: "Couldn't delete the user, because he is the only one responsible for a usergroup. Every usergroup must has at least one responsible",
    },
    ADMIN_MUST_REMAIN: {
      de: 'Der Benutzer kann nicht gelöscht werden, da er alleiniger Administrator des Mandanten ist. Für jeden Mandanten muss mindestens ein Administrator vorhanden sein.',
      en: "Couldn't delete the user, because he is the only Administrator of the client. Every client must has at least one Administrator.",
    },
    INSUFFICIENT_RIGHTS: {
      de: 'Ihnen wurde das Recht zur Bearbeitung entzogen. Bitte wenden Sie sich an den zuständigen Administrator.',
      en: 'You have been deprived of the right to edit. Please contact the responsible administrator.',
    },
  },
  GROUP_DETAIL: {
    TITLE: {
      de: 'Benutzergruppe "{{groupname}}" bearbeiten',
      en: 'Edit Usergroup "{{groupname}}"',
    },
    INFO_TITLE: {
      de: 'Benutzergruppe bearbeiten',
      en: 'Edit Usergroup',
    },
    NAME: {
      de: 'Name',
      en: 'Name',
    },
    DESCRIPTION: {
      de: 'Beschreibung',
      en: 'Description',
    },
    GROUP_ACTIVE: {
      de: 'Benutzergruppe aktiv',
      en: 'Usergroup Active',
    },
  },
  USER_VIEW: {
    TITLE: {
      de: 'Benutzer anzeigen',
      en: 'Show User',
    },
    USER_TILE_TITLE: {
      de: 'Benutzer',
      en: 'User',
    },
    INTERN_USER_TILE_TITLE: {
      de: 'Stammbenutzer',
      en: 'Internal User',
    },
    RIGHTS_TILE_TITLE: {
      de: 'Rechte',
      en: 'Rights',
    },
    GROUPS_TILE_TITLE: {
      de: 'Benutzergruppen',
      en: 'Usergroups',
    },
    USER_NAME: {
      de: 'Name:',
      en: 'Name:',
    },
    USER_EMAIL: {
      de: 'E-Mail:',
      en: 'E-Mail:',
    },
    USER_COCUUN_ID: {
      de: 'Cocuun-ID:',
      en: 'Cocuun-ID:',
    },
    USER_INVITE_TOKEN: {
      de: 'Einladungscode:',
      en: 'Invitation Token:',
    },
    USER_ADDITIONAL_INFO: {
      de: 'Zusatzinformation:',
      en: 'Additional Information:',
    },
    LAST_CHANGE: {
      de: 'Letzte Änderung:',
      en: 'Last Change:',
    },
    CREATION: {
      de: 'Anlage:',
      en: 'Creation:',
    },
    ENTRY: {
      de: 'Beitritt:',
      en: 'Entry:',
    },
    PROPERTY: {
      de: 'Eigenschaften:',
      en: 'Properties:',
    },
    CREATED_ON_BY: {
      de: '{{date}} von {{user}}',
      en: '{{date}} by {{user}}',
    },
    CREATED_ON_BY_TICKET: {
      de: '{{date}} per Ticket',
      en: '{{date}} by ticket',
    },
    EDITED_ON_BY: {
      de: '{{date}} von {{user}}',
      en: '{{date}} by {{user}}',
    },
    INVITE_USER: {
      de: 'Benutzer einladen',
      en: 'Invite User',
    },
    SHARE_INVITE_CODE: {
      de: 'Einladungscode teilen',
      en: 'Share Invitation Token',
    },
    EDIT_USER: {
      de: 'Benutzer bearbeiten',
      en: 'Edit User',
    },
    DELETE_USER: {
      de: 'Benutzer löschen',
      en: 'Delete User',
    },
    USER_INFO_CHANGED: {
      de: '(geändert)',
      en: '(changed)',
    },
    WILL_BE_INVITED: {
      de: '(wird eingeladen)',
      en: '(will be invited)',
    },
    WILL_NOT_BE_INVITED: {
      de: '(wird nicht eingeladen)',
      en: '(will not be invited)',
    },
    COCUUN_TITLE: {
      de: 'Cocuun:',
      en: 'Cocuun:',
    },
    ADMINISTRATION_TITLE: {
      de: 'Administration:',
      en: 'Administration:',
    },
    SHOW_MAIL_TEXT: {
      de: 'E-Mail Text anzeigen',
      en: 'Show email text',
    },
  },
  GROUP_VIEW: {
    TITLE: {
      de: 'Benutzergruppe anzeigen',
      en: 'Show Usergroup',
    },
    GROUP_NAME: {
      de: 'Name:',
      en: 'Name:',
    },
    GROUP_DESCRIPTION: {
      de: 'Beschreibung:',
      en: 'Description:',
    },
    GROUP_MANAGER: {
      de: 'Zuständigkeit:',
      en: 'Responseability:',
    },
    GROUP_TILE_TITLE: {
      de: 'Benutzergruppe',
      en: 'Usergroup',
    },
    MEMBER_TILE_TITLE: {
      de: 'Mitglieder',
      en: 'Members',
    },
    MEMBER_TILE_TITLE_ONE: {
      de: 'Mitglied',
      en: 'Member',
    },
    COMPARE_GROUPS: {
      de: 'Benutzergruppen vergleichen',
      en: 'Compare Usergroups',
    },
    DELETE_GROUPS: {
      de: 'Benutzergruppen löschen',
      en: 'Delete Usergroups',
    },
    GROUP_INFO_CHANGED: {
      de: '(geändert)',
      en: '(changed)',
    },
    GROUP_CREATION_DATE: {
      de: 'Anlage:',
      en: 'Creation:',
    },
    GROUP_CREATED_ON_BY: {
      de: '{{date}} von {{user}}',
      en: '{{date}} by {{user}}',
    },
    GROUP_CHANGE_DATE: {
      de: 'Letzte Änderung:',
      en: 'Last Change:',
    },
    STATUS: {
      de: 'Status:',
      en: 'Status:',
    },
    ACTIVE: {
      de: 'Aktiv',
      en: 'Active',
    },
    DISABLED: {
      de: 'Inaktiv',
      en: 'Inactive',
    },
  },
  USERLIST: {
    ADD: {
      de: 'Benutzer anlegen',
      en: 'Create a User',
    },
  },
  ACTION_INFO: {
    WILL_BE_ADDED: {
      de: 'wird hinzugefügt',
      en: 'will be added',
    },
    WILL_BE_REMOVED: {
      de: 'wird entfernt',
      en: 'will be removed',
    },
    WILL_BE_DELETED: {
      de: 'wird gelöscht',
      en: 'will be deleted',
    },
    WILL_BE_DISABLED: {
      de: 'wird deaktiviert',
      en: 'will be disabled',
    },
    INFO_HAS_CHANGED: {
      de: 'Info geändert',
      en: 'Info geändert',
    },
  },
  ACTIVATE_GROUP: {
    ACTIVATE_TITLE: {
      de: 'Benutzergruppe Aktivieren',
      en: 'Activate Usergroup',
    },
    DEACTIVATE_TITLE: {
      de: 'Benutzergruppe Deaktivieren',
      en: 'Deactivate Usergroup',
    },
    ACTIVATE_BODY: {
      de: 'Soll die Benutzergruppe aktiviert werden?',
      en: 'Activate this usergroup?',
    },
    DEACTIVATE_BODY: {
      de: 'Soll die Benutzergruppe deaktiviert werden?',
      en: 'Deactivate this usergroup?',
    },
  },
  CHANGE_AVATAR: {
    DELETE: {
      de: 'Löschen',
      en: 'Delete',
    },
    CHOOSE: {
      de: 'Auswählen',
      en: 'Choose Image',
    },
    CANCEL: {
      de: 'Abbrechen',
      en: 'Cancel',
    },
    ACCEPT: {
      de: 'Übernehmen',
      en: 'Save',
    },
  },
  INFOTEXT: {
    LOGIN: {
      de: `<p>Melden Sie sich mit der E-Mail-Adresse Ihres bestehenden Cocuun Kontos und dem entsprechenden Kennwort an.</p>
            <p>Der Zugang zur Cocuun Administration ist Ihnen nur gestattet, wenn Sie mindestens eines der Rechte "Administrator", "darf Benutzer pflegen" oder "darf Benutzergruppen pflegen" besitzen.</p>`,
      en: `<p>Log in with the e-mail address and the corresponding password of your Cocuun account.</p>
            <p>The access to the administration platform is only granted, if you have at least one of the permissions "Administrator", "Can manage Users" or "Can Manage Usergroups".</p>`,
    },
    DASHBOARD: {
      de: `<p>Im Dashboard erhalten Sie eine cocuunweite Übersicht über Ihre Organisation sowie sämtlicher letzter Änderungen aller Administratoren.</p>
          <p>Hinter Ihrem Namen sehen Sie Ihr höchstes Recht in dieser Administration:</p>
          <ul><li><p>Administrator<br />
          erlaubt die Pflege aller Benutzer und Benutzergruppen</p></li>
          <li><p>Darf Benutzer pflegen<br />
          erlaubt das Anlegen, Bearbeiten und Löschen von Benutzern sowie die<br />
		  Vergabe der zentralen Rechte für die Administration und Cocuun</p></li>
          <li><p>Darf Benutzergruppen pflegen<br />
          erlaubt das Anlegen von Benutzergruppen sowie das Bearbeiten und Löschen der Gruppen, für die Sie als Zuständiger definiert sind</p></li></ul>
          <p>Der Zugang erfolgt über die URL: www.admin.cocuun.de oder den Eintrag „Cocuun-Administration“ im Hauptmenü in Cocuun und ist ausschließlich für Personen mit mindestens einem der oben erläuterten Rechte möglich.</p>
		  <p>Im Dashboard sehen Sie die Bereiche:</p> 
          <p><b>Mandanteninformationen</b><br />
          Diese Informationen (Name, Anschrift und Telefonnummer Ihrer Organisation) können aktuell nur vom Cocuun Team verändert werden. Bitte wenden Sie sich bei Änderungswünschen über das Cocuun Service Center an den Support.</p>
          <p><b>Statistik</b><br />
          Die Statistik ist organisationsspezifisch. Sie zeigt den aktuell verbrauchten Speicherplatz. Zudem gibt sie Aufschluss über die Anzahl der Gruppenordner, ausgetauschten Dateien und Beiträge.</p>
          <b>Benutzer und Benutzergruppen</b><br />
          Mit einem Klick auf die Bereichstitel „Benutzer“ oder „Benutzergruppe“ können Sie in die entsprechende Ansicht wechseln. Diese kann dann individuell sortiert und gefiltert werden und steht Ihnen zusätzlich in einer Kachelansicht zur Verfügung.</p>
          <p>Mit einem Klick auf „Administratoren", „Stammbenutzer", „Aktive" oder „Meine" verzweigen Sie direkt in die entsprechend gefilterte Ansicht.</p>
          <p>Unter dem Titelbereich befindet sich die Liste der zuletzt bearbeiteten Elemente des jeweiligen Bereichs. Diese Liste bezieht sich auf alle Änderungen aller Administratoren und ist nach dem zeitlichen Verlauf sortiert.</p>`,
      en: `<p>The dashboard gives an overview of your client and the last changes of all administrators.</p>
          <p>You can see your highest permission on the administration platform right after your name:</p>
          <ul><li><p>Administrator<br />
          allows you to maintain all the users and usergroups</p></li>
          <li><p>Can Manage Users<br />
          allows you to create, edit and delete users and their permissions within the administration platform and Cocuun</li></p>
          <li><p>Can Manage Usergroups<br />
          allows the creation of new usergroups and to edit and delete the usergroups for which you are responsible</p></li></ul>
          <p>Access the administration via the URL: www.admin.cocuun.de or via “Cocuun Administration” in the main menu in Cocuun. The access is only granted for users with at least one of the permissions mentioned above.</p>
          <p>The dashboard shows the following fields:</p>
          <p><b>Client Information</b><br />
          This information (name, address and phone number of the client) can currently only be changed by the Cocuun team. If you have any change requests, please contact the support via the Cocuun Service Center.</p>
          <p><b>Statistics</b><br />
          The statistic is client specific. It shows the used diskspace, the amount of folders, shared files and posts.</p>
          <p><b>Users and Usergroups</b><br />
          A click on the title “Users” or “Usergroups” leads you to the corresponding view. The view can then be sorted and filtered individually. A tile view is possible.</p>
          <p>A click on the additional information (“Administrator” or “Internal Users” below the title “Users”; “Active” or “Mine” below the title “Usergroups”) leads you to the corresponding filtered view.</p>
          <p>Below the title area, you can see a listing of the elements of each section, which were last edited. The listing shows the changes of all administrators and is sorted according to the time course.</p>`,
    },
    SELECT_USER: {
      de: `<p>Über den +-Button können Sie einen Benutzer dem Mandanten hinzufügen, ihn mit Rechten versehen und Benutzergruppen zuordnen. Mit einem Klick auf einen Benutzer öffnen Sie seine Detailinformationen.</p>
          <p>Die Mehrfachauswahl ermöglicht Ihnen das einfache Bearbeiten von Informationen, Rechten und Gruppenzugehörigkeiten mehrerer Benutzer gleichzeitig.</p>
          <p>Diese Ansicht kann individuell sortiert und gefiltert werden. Sie steht Ihnen zusätzlich in einer Kachelansicht zur Verfügung. Haben Nutzer die Einladung zu Ihrem Mandanten noch nicht angenommen, werden sie in kursiver Schrift dargestellt.</p>
          <p><b>Achtung:</b> Ordnen Sie einen Benutzer einer Benutzergruppe zu, so wird er automatisch auch den Ordnern und Gruppenchats in Cocuun zugeordnet, in denen die Benutzergruppe berechtigt ist. Ihm stehen dann sofort die Inhalte zur Verfügung.</p>`,
      en: `<p>Add users to the client via the +-button. You can grant him rights and assign him to usergroups. A click on the user opens his detailed information.</p>
          <p>The multiple selection allows you to edit the information, rights and assignments to usergroups of multiple users at the same time.</p>
          <p>The view can be sorted and filtered individually. A tile view is possible. Users who have not accepted the invitation to the client yet, are written in italic letters.</p>
          <p><b>Attention:</b> If you assign a user to a usergroup, he is automatically assigned to the folders and chat groups in Cocuun for which the usergroup is authorized.</p>`,
    },
    USER_DETAIL: {
      de: `<p>Hier sehen Sie die Details zum ausgewählten Benutzer. Neben den hinterlegten Stammdaten zeigt die Übersicht die Rechte des Benutzers und die Benutzergruppen, denen er zugeordnet ist.</p>
          <p>Sind Sie Administrator oder besitzen das Recht, Benutzer zu pflegen, können Sie die einzelnen Kacheln anklicken und gelangen direkt zum entsprechenden Arbeitsschritt, um die Daten zu ändern.</p>
          <p>Die Schaltfläche unter den drei Kacheln bietet Ihnen die Möglichkeit, den Benutzer einzuladen, sofern er noch nicht eingeladen wurde. Wurde er bereits eingeladen, hat die Einladung jedoch noch nicht angenommen, kann der Einladungscode erneut geteilt werden. Zudem besteht die Möglichkeit, den Benutzer direkt zu bearbeiten oder ihn zu löschen.</p>`,
      en: `<p>The details of the user are displayed. You can see the master record of the user, his rights and the assigned usergroups.</p>
          <p>If you are an administrator or if you have the right to manage users, you can click on the tiles. It leads you to the corresponding section for editing the data.</p>
          <p>The switch below the three tiles allows you to invite the user, if this has not happened yet. If you have already invited the user but he has not accepted the invitation yet, you can share the invitation token again. The other switches allow you to edit or delete the user.</p>`,
    },
    SELECT_USERGROUP: {
      de: `<p>Über den +-Button können Sie eine neue Benutzergruppe für den Mandanten anlegen. Sie können entscheiden, ob diese aktiv oder inaktiv sein soll. Eine inaktive Benutzergruppe ist in Cocuun zwar sichtbar, kann jedoch keinen Profilen von Gruppenordnern oder Gruppenchats hinzugefügt werden. Die inaktiven Benutzergruppen werden kursiv dargestellt.</p>
          <p>Sie können der Gruppe Benutzer zuordnen und festlegen, wer für die Pflege der Gruppe zuständig sein soll. Mit einem Klick auf die Benutzergruppe öffnen Sie die Detailinformationen.</p>
          <p>Diese Ansicht kann individuell sortiert und gefiltert werden. Sie steht Ihnen zusätzlich in einer Kachelansicht zur Verfügung.</p>
          <p><b>Achtung:</b> Ordnen Sie einen Benutzer einer Benutzergruppe zu, so wird er automatisch den Ordnern und Gruppenchats in Cocuun zugeordnet, in denen die Benutzergruppe berechtigt ist. Ihm stehen dann sofort die Inhalte zur Verfügung.</p>`,
      en: `<p>Add new usergroups to the client via the +-button. You can decide, if you want the group to be active or inactive. A usergroup which is inactive is visible in Cocuun but cannot be assigned to profiles of group folders or chat groups in Cocuun. Usergroups that are inactive are written in italic letters.</p>
          <p>You can add users to the usergroup and define the users who are responsible for the group. A click on the usergroup opens detailed information about the group.</p>
          <p>The view can be sorted and filtered individually. A tile view is possible.</p>
          <p><b>Attention:</b> If you assign a user to a usergroup, he is automatically assigned to the folders and chat groups in Cocuun for which the usergroup is authorized.</p>`,
    },
    USERGROUP_DETAIL: {
      de: `<p>Hier sehen Sie die Details zur ausgewählten Benutzergruppe.</p>
          <p>Sind Sie Administrator oder besitzen das Recht, Benutzergruppen zu pflegen, können Sie die einzelnen Kacheln anklicken und gelangen direkt zum entsprechenden Arbeitsschritt, um die Angaben zu ändern.</p>
          <p>Die Schaltfläche unter den Kacheln ermöglicht eine direkte Bearbeitung der Benutzergruppe.<br />
		      Zudem besteht die Möglichkeit, über die Schaltfläche „Benutzergruppe vergleichen“ eine zweite Benutzergruppe auszuwählen, mit der sie verglichen werden soll.<br />
		      Eine Benutzergruppe kann nur gelöscht werden, wenn sie in Cocuun keinem Profil von Gruppenordnern oder Gruppenchats zugeordnet ist. Ist das Löschen nicht möglich, kann die Benutzergruppe deaktiviert werden. Mit ihrer Deaktivierung verbleibt die Benutzergruppe zwar in den zugeordneten Gruppenordnern und -chats in Cocuun, kann jedoch keinem neu angelegten Gruppenordner oder -chat hinzugefügt werden. Auch als inaktive Benutzergruppe ist sie in Cocuun sichtbar.</p>`,
      en: `<p>You can see the details of the selected usergroup.</p>
          <p>If you are an administrator or if you have the right to manage usergroups, you can click on the tiles. It leads you to the corresponding section for editing the data.</p>
          <p>The switch below the tiles allows you to edit the usergroup.<br />
		      You have the possibility to compare two usergroups via the switch “Compare Usergroups”. You are asked to select a second usergroup.<br /> 
		      A usergroup can only be deleted if it is not assigned to any profile for group folders or chat group in Cocuun. If you do not have the possibility to delete the usergroup, you can deactivate it. With its deactivation, the usergroup remains in the assigned group folders and chat groups in Cocuun but cannot be assigned to new group folders or chat groups. The usergroup is still visible in Cocuun.</p>`,
    },
    CREATE_USER: {
      de: `<p><b>Informationen erfassen</b><br />
          Hinterlegen Sie den Vor- und Nachnamen des Benutzers sowie eine E-Mail-Adresse, an die die Einladung zum Mandanten versendet wird. Optional können Sie Zusatzinformationen pflegen, die nur in der Administration erscheinen.<br /> 
		      Als Non-Profit-Organisation sind die Hauptamtlichen und jene Mitglieder, die den Einsatz von Cocuun in Ihrer Organisation koordinieren, Ihre Stammbenutzer. Nutzen Sie Cocuun als Unternehmen, geben Sie allen festangestellten Mitarbeitern die Eigenschaft des Stammbenutzers.</p> 
		      <p>Vergeben Sie die Eigenschaft „für Stammbenutzer sichtbar“. So ermöglichen Sie es anderen Teilnehmern, die Stammbenutzer und Ordneradministrator sind, ihren Ordnern Teilnehmer hinzuzufügen, mit denen sie bisher noch keine Inhalte teilen. Sobald der Benutzer Benutzergruppen zugeordnet wird, erhält er automatisch die Eigenschaft „für Stammbenutzer sichtbar“, die ihm nicht wieder entzogen werden kann.</p>
          <p>Möchten Sie den Benutzer anlegen, ohne eine Einladung zu generieren, deaktivieren Sie „Benutzer einladen“ über die Betätigung des Schiebereglers.</p>
          <p><b>Rechte setzen (optional)</b><br />
          Entscheiden Sie, welche Berechtigungen der Benutzer in der Administration erhalten soll. Wird ein Benutzer zum „Administrator“ ernannt, stehen ihm automatisch die Rechte „darf Benutzer pflegen“ und „darf Benutzergruppen pflegen“ zu. Die Rechte zur Pflege der Benutzer oder zur Pflege der Benutzergruppen können unabhängig von anderen Rechten erteilt werden.</p>
          <p>Erteilen Sie dem Benutzer Rechte innerhalb von Cocuun. Die Rechte „darf frei einladen“, „darf Gruppenordner anlegen“ und „darf Gruppenchats anlegen“ können unabhängig voneinander erteilt werden.</p>
          <p><b>Benutzergruppen zuordnen (optional)</b><br />
          Ordnen Sie den Benutzer einer oder mehreren Benutzergruppen zu. So wird er automatisch in den entsprechenden Gruppenordnern und -chats innerhalb von Cocuun berechtigt. Ihm stehen dann sofort die Inhalte zur Verfügung.</p>
          <p><b>Zusatzinformationen erfassen (optional)</b><br />
          Erfassen Sie für jede Benutzergruppe, denen der Benutzer zugeordnet wurde, weitere Informationen. Dabei können Sie zwischen einer Zusatzinformation, die nur für Administratoren und einer, die für alle Teilnehmer eines Gruppenordners sichtbar ist, unterscheiden.</p>
          <p><b>Zusammenfassung</b><br />
          Das Betätigen der Schaltfläche „Fertig“ legt den neuen Benutzer an.</p>
          <p>Möchten Sie bestimmte Angaben doch noch einmal ändern? Klicken Sie auf eine Kachel, die Sie direkt zum entsprechenden Bearbeitungsschritt führt.</p>`,
      en: `<p><b>Add Information</b><br />
          Add the first and last name of the new user. Enter an e-mail address for the invitation to the client. You can define additional information that are only visible on the administration platform.< /br> 
		      If you are a non-profit-organization, your full-time employees and the users who coordinate the use of Cocuun in your organisation, are “Internal Users”. As a company, the permanent staff has the characteristic “Internal User”.</p> 
		      <p>The characteristic “Visible for Internal Users” allows internal users to see the user in Cocuun. As soon as you assign a user to a usergroup, the characteristic “Visible for Internal Users” is automatically set and cannot be revoked.</p>
          <p>If you want to create the user without generating an invitation, deactivate the switch “Invite User”.</p>
          <p><b>Set Rights (optional)</b><br />
          Decide, which rights you want to grant to the user for the administration platform. Designating him as “Administrator”, grants him automatically the rights “Can Manage Users” and “Can Manage Usergroups”. The rights to manage users or to manage usergroups can be assigned regardless of other rights.</p>
          <p>Decide, which rights you want to grant to the user in Cocuun. The rights “Can Freely Invite”, “Can Create Group Folders” and “Can Create Chat Groups” can be assigned regardless of other rights.</p>
          <p><b>Assign to Usergroups (optional)</b><br />
          Assign the user to one or multiple usergroups. Hence, he is automatically authorized in the corresponding group folders and chat groups in Cocuun.</p>
          <p><b>Add additional information (optional)</b><br />
          Gather additional information to the user for each assigend usergroup. Differentiate between information only visible for administrators and information visible for all members of the group folder.</p>
          <p><b>Overview</b><br />
          The user is created after pressing the button “Finish”.</p>
          <p>If you would like to change the information again, click on the corresponding tile. It leads you back to the step for editing.</p>`,
    },
    EDIT_USER: {
      de: `<p><b>Informationen anpassen (optional)</b><br />
          Bearbeiten Sie den angelegten Stammsatz des Benutzers. Hat der Benutzer die Einladung bereits angenommen, sehen Sie im Vergleich zu dem von Ihnen angelegten Stammsatz das Cocuun Konto, über das der Teilnehmer die Einladung angenommen hat. Hat der Benutzer die Einladung noch nicht angenommen, wird der Einladungscode angezeigt.</p>
          <p>Die Eigenschaft des Stammbenutzers kann erteilt oder entzogen werden.< /br> 
		      Als Non-Profit-Organisation sind die Hauptamtlichen und jene Mitglieder, die den Einsatz von Cocuun in Ihrer Organisation koordinieren, Ihre Stammbenutzer. Nutzen Sie Cocuun als Unternehmen, geben Sie allen festangestellten Mitarbeitern die Eigenschaft des Stammbenutzers.</p> 
		      <p>Die Eigenschaft „für Stammbenutzer sichtbar“ ermöglicht es den Stammbenutzern, den entsprechenden Nutzer in Cocuun zu sehen. Sobald der Benutzer Benutzergruppen zugeordnet wird, erhält er automatisch die Eigenschaft „für Stammbenutzer sichtbar“, die ihm nicht wieder entzogen werden kann.</p>
          <p><b>Rechte setzen (optional)</b><br />
          Bearbeiten Sie die dem Benutzer erteilten Berechtigungen. Mit einem Klick auf die entsprechenden Rechte können Sie die Berechtigungen des Benutzers in der Administration und innerhalb von Cocuun ändern.</p>
          <p>Wird ein Benutzer zum „Administrator“ ernannt, stehen ihm automatisch die Rechte „darf Benutzer pflegen“ und „darf Benutzergruppen pflegen“ zu. Die Rechte zur Pflege der Benutzer oder zur Pflege der Benutzergruppen können unabhängig von anderen Rechten erteilt werden.</p>
          <p>Die Rechte „darf frei einladen“, „darf Gruppenordner anlegen“ und „darf Gruppenchats anlegen“ können unabhängig voneinander erteilt werden.</p>
          <p><b>Benutzergruppen zuordnen (optional)</b><br />
          Entfernen Sie den Benutzer aus ihm bereits zugeordneten Benutzergruppen. Zusätzlich können Sie den Benutzer neuen Benutzergruppen hinzufügen.</p>
          <p><b>Achtung:</b> Ordnen Sie einen Benutzer einer Benutzergruppe zu, so wird er automatisch den Ordnern und Gruppenchats in Cocuun zugeordnet, in denen die Benutzergruppe berechtigt ist. Ihm stehen dann sofort die Inhalte zur Verfügung.</p>
          <p><b>Zusatzinformationen pflegen (optional)</b><br />
          Bearbeiten Sie die Zusatzinformationen, die für jede Benutzergruppe, denen der Benutzer zugeordnet ist, erfasst sind. Unterscheiden Sie zwischen Zusatzinformationen, die nur für Administratoren und denen, die für alle Teilnehmer eines Gruppenordners sichtbar sind.</p>
          <p><b>Zusammenfassung</b><br />
          Die Zusammenfassung zeigt alle von Ihnen vorgenommenen Änderungen am Benutzer. Möchten Sie bestimmte Angaben doch noch einmal ändern? Klicken Sie auf eine Kachel, die Sie direkt zum entsprechenden Bearbeitungsschritt führt.</p>
          <p>Das Betätigen der Schaltfläche „Fertig“ aktiviert die vorgenommenen Änderungen.</p>`,
      en: `<p><b>Edit Information (optional)</b><br />
          Edit the master record of the user. If the user has already accepted the invitation, you can compare the master record you have created with the Cocuun account through which the participant has accepted the invitation. If the user has not accepted the invitation yet, the invitation token is displayed.</p>
          <p>The characteristic “Internal User” can be assigned or revoked.< /br> 
		      If you are a non-profit-organization, your full-time employees and the users who coordinate the use of Cocuun in your organisation are “Internal Users”. As a company, the permanent staff has the characteristic “Internal User”.</p> 
		      <p>The characteristic “Visible for Internal Users” allows internal users to see the user in Cocuun. As soon as you assign a user to a usergroup, the characteristic “Visible for Internal Users” is automatically set and cannot be revoked.</p>
          <p><b>Set Rights (optional)</b><br />
          Edit the rights you have granted to the user. Clicking on the corresponding rights changes the rights of the user on the administration platform and in Cocuun.</p>
          <p>If you designate a user as “Administrator”, he has automatically the rights “Can Manage Users” and “Can Manage Usergroups”. The rights to manage users or to manage usergroups can be assigned regardless of other rights.</p>
          <p>The rights “Can Freely Invite”, “Can Create Group Folders” and “Can Create Chat Groups” can be assigned regardless of other rights.</p>
          <p><b>Assign to Usergroups (optional)</b><br />
          Delete the user from assigned usergroups or add him to new usergroups.</p>
          <p><b>Attention:</b> If you assign a user to a usergroup, he is automatically assigned to the folders and chat groups in Cocuun, in which the usergroup is authorized.</p>
          <p><b>Maintain additional information (optional)</b><br />
          Change the addtional informations of the assigend usergroups. Differentiate between the information only visible for administrators and the information visible for all members of the group folder.</p>
          <p><b>Overview</b><br />
          The overview shows all the changes you have made. If you would like to change the information again, click on the corresponding tile. It leads you back to the step for editing.</p>
          <p>Press the button “Finish” to confirm the changes.</p>`,
    },
    EDIT_MULTI_USER: {
      de: `<p><b>Ausgewählte Benutzer</b><br />
          Die Liste zeigt die Benutzer, die Sie zur gemeinsamen Bearbeitung ausgewählt haben.</p>
          <p><b>Informationen anpassen (optional)</b><br />
          In diesem Bearbeitungsschritt können Sie die Zusatzinformationen der ausgewählten Benutzer vereinheitlichen. Individuelle Zusatzinformationen werden überschrieben.</p>
          <p>Die Eigenschaften „Stammbenutzer“ und „für Stammbenutzer sichtbar“ können für die Benutzer angeglichen werden. Als Non-Profit-Organisation sind die Hauptamtlichen und jene Mitglieder, die den Einsatz von Cocuun in Ihrer Organisation koordinieren, Ihre Stammbenutzer. Nutzen Sie Cocuun als Unternehmen, geben Sie allen festangestellten Mitarbeitern die Eigenschaft des Stammbenutzers.</p>
          <p>Greift eine Eigenschaft beim Betreten der Maske nur für einen Teil der ausgewählten Benutzer, ist das Symbol blau hinterlegt. Sobald die Eigenschaft für alle gesetzt wird, wechselt die Farbe auf grün. Wird die Eigenschaft allen entzogen, ist das Symbol grau.</p> 
		      <p>Die Eigenschaft „für Stammbenutzer sichtbar“ kann nicht entzogen werden, sobald mindestens einer der ausgewählten Benutzer einer Benutzergruppe zugeordnet ist.</p>
          <p><b>Rechte setzen (optional)</b><br />
          Sowohl die Berechtigungen der ausgewählten Benutzer in der Administration als auch innerhalb von Cocuun können vereinheitlicht werden. Gilt ein Recht beim Betreten der Maske nur für einen Teil der ausgewählten Benutzer, ist das Symbol blau hinterlegt. Sobald das Recht allen ausgewählten Benutzern durch einen Klick auf das Symbol übertragen wird, wechselt es auf grün. Das graue Symbol kennzeichnet, dass das entsprechende Recht allen entzogen wurde.</p>
          <p>Werden die Benutzer zu Administratoren ernannt, erhalten sie automatisch die Rechte „darf Benutzer pflegen“ und „darf Benutzergruppen pflegen“. Die Rechte zur Pflege der Benutzer oder zur Pflege der Benutzergruppen können unabhängig von anderen Rechten erteilt werden.</p>
          <p>Die Rechte „darf frei einladen“, „darf Gruppenordner anlegen“ und „darf Gruppenchats anlegen“ können unabhängig voneinander erteilt werden.</p>
          <p><b>Benutzergruppen zuordnen (optional)</b><br />
          Die Liste „Zugeordnete Benutzergruppen entfernen“ zeigt nur die Benutzergruppen, in denen alle ausgewählten Benutzer Teilnehmer sind. Mit einem Klick kann deren Zuordnung entfernt werden. Sie können ebenso alle ausgewählten Benutzer mit einem Klick einer Benutzergruppe zuordnen.</p>
          <p><b>Achtung:</b> Ordnen Sie die Benutzer einer Benutzergruppe zu, so werden sie automatisch den Ordnern und Gruppenchats in Cocuun zugeordnet, in denen die Benutzergruppe berechtigt ist. Ihnen stehen dann sofort die Inhalte zur Verfügung.</p>
          <p><b>Zusatzinformation pflegen (optional)</b><br />
          Bearbeiten Sie die Zusatzinformationen für jede Benutzergruppe, denen die ausgewählten Benutzer zugeordnet sind. So können Sie die Zusatzinformation für mehrere Benutzer vereinheitlichen. Unterscheiden Sie zwischen Zusatzinformationen, die nur für Administratoren und denen, die für alle Teilnehmer eines Gruppenordners sichtbar sind.</p>
          <p><b>Zusammenfassung</b><br />
          Die Zusammenfassung zeigt alle von Ihnen vorgenommenen Änderungen an den Benutzern. Möchten Sie bestimmte Angaben doch noch einmal ändern? Klicken Sie auf eine Kachel, die Sie direkt zum entsprechenden Bearbeitungsschritt führt.</p>
          <p>Das Betätigen der Schaltfläche „Fertig“ aktiviert die vorgenommenen Änderungen.</p>`,
      en: `<p><b>Selected Users</b><br />
          The listing shows the users you want to edit simultaneously.</p>
          <p><b>Edit Information (optional)</b><br />
          You can unify the additional information of all the users you have selected. The individual data will be overwritten.</p>
          <p>The characteristics “Internal User” and “Visible for Internal Users” can be unified. If you are a non-profit-organization, your full-time employees and the users who coordinate the use of Cocuun in your organisation, are “Internal Users”. As a company, the permanent staff has the characteristic “Internal User”.</p>
          <p>If only a few of the selected users have the characteristic when entering the mask, the icon is blue. As soon as you set the characteristic for all the users, the icon changes its colour to green. If you deprive the characteristic for all, the icon is grey.</p> 
		      <p>The characteristic “Visible for Internal Users” cannot be deprived as soon as at least one of the selected user is assigned to a usergroup.</p>
          <p><b>Set Rights (optional)</b><br />
          You can unify the rights for the selected users on the administration platform and in Cocuun. If only a few of the selected users have the right when entering the mask, the icon is blue. As soon as you grant the right to all the users by clicking on the icon, it changes its colour to green. The icon is grey, if you have deprived the right for all.</p>
          <p>If you designate the users as “Administrator”, they automatically have the rights “Can Manage Users” and “Can Manage Usergroups”. The rights to manage users or to manage usergroups can be assigned regardless of other rights.</p>
          <p>The rights “Can Freely Invite”, “Can Create Group Folders” and “Can Create Chat Groups” can be assigned regardless of other rights.</p>
          <p><b>Assign to Usergroups (optional)</b><br />
          The list “Remove Assigned Usergroups” shows the usergroups in which all the selected users are part of. A click on the usergroup provokes, that the users are deleted from the usergroup. You can also add all the selected users to a usergroup with one click.</p>
          <p><b>Attention:</b> If you assign the users to a usergroup, they are automatically assigned to the folders and chat groups in Cocuun, in which the usergroup is authorized.</p>
          <p><b>Maintain additional information (optional)</b><br />
		      Change the additional information to the users of the assigned usergroups. This allows to unify the additional information for several users. Differentiate between the information only visible for administrators and the information visible for all members of the group folder.</p>
		      <p><b>Overview</b><br />
          The overview shows all the changes you have made. If you would like to change the information again, click on the corresponding tile. It leads you back to the step for editing.</p>
          <p>Press the button “Finish” to confirm the changes.</p>`,
    },
    DELETE_USER: {
      de: `<p>Sie können einen oder mehrere Benutzer aus dem Mandanten entfernen. Er wird dann aus allen Gruppenordnern und Gruppenchats des Mandanten in Cocuun gelöscht. Bestätigen Sie das Löschen mit der Eingabe Ihres Kennwortes.</p>
		      <p><b>Achtung:</b> Das Löschen kann nicht rückgängig gemacht werden!</p>`,
      en: `<p>You can delete one or multiple users from the client. He will then be deleted from all the group folders and chat groups of the client in Cocuun. Confirm the process with your password.</p>
		      <p><b>Attention:</b> If you delete a user, it cannot be revoked!</p>`,
    },
    INVITE_TOKEN: {
      de: `<p>Teilen Sie den Code mit dem eingeladenen Benutzer. So wird er daran erinnert, dass seine Annahme noch aussteht.</p>`,
      en: `<p>You can share the invitation token with the user once again. Hence, he is reminded, that he has not accepted the invitation yet.</p>`,
    },
    CREATE_USERGROUP: {
      de: `<p><b>Informationen erfassen</b><br />
          Geben Sie der neuen Benutzergruppe einen treffenden Namen. Optional können Sie eine Beschreibung und ein individuelles Benutzergruppenbild hinzufügen.<br />
		      Sie können entscheiden, ob die Benutzergruppe aktiv oder inaktiv sein soll. Eine inaktive Benutzergruppe ist in Cocuun zwar sichtbar, kann jedoch keinen Profilen von Gruppenordnern oder Gruppenchats hinzugefügt werden.</p>
          <p><b>Benutzer hinzufügen (optional)</b><br />
          Fügen Sie der Benutzergruppe einen oder mehrere Benutzer hinzu. Möchten Sie die Benutzergruppe zunächst ohne Teilnehmer erstellen, überspringen Sie diesen Schritt. Sie können zu einem späteren Zeitpunkt die Benutzergruppe bearbeiten und Teilnehmer hinzufügen.</p>
          <p><b>Achtung:</b> Ordnen Sie einen Benutzer einer Benutzergruppe zu, so wird er automatisch den Ordnern und Gruppenchats in Cocuun zugeordnet, in denen die Benutzergruppe berechtigt ist. Ihm stehen dann sofort die Inhalte zur Verfügung.</p>
          <p><b>Zusatzinformationen erfassen (optional)</b><br />
          Erfassen Sie weitere Informationen für die zugeordneten Benutzer. Dabei können Sie zwischen einer Zusatzinformation, die nur für Administratoren und einer, die für alle Teilnehmer des Gruppenordners sichtbar ist, unterscheiden. </p>
          <p><b>Zuständigkeit festlegen</b><br />
          Entscheiden Sie, wer die Benutzergruppe pflegen soll. Zur Auswahl stehen die Benutzer Ihres Mandanten, die das Recht „darf Benutzergruppen pflegen“ besitzen. Die für die Benutzergruppe Zuständigen dürfen die Benutzergruppe bearbeiten, vergleichen oder löschen.</p>
          <p><b>Zusammenfassung</b><br />
          Das Betätigen der Schaltfläche „Fertig“ legt die neue Benutzergruppe an.</p>
          <p>Möchten Sie bestimmte Angaben doch noch einmal ändern? Klicken Sie auf eine Kachel, die Sie direkt zum entsprechenden Bearbeitungsschritt führt.</p>`,
      en: `<p><b>Add Information</b><br />
          Name the new usergroup. You can add a description and a picture if you want to.<br />
		      You can decide if you want the usergroup to be active or inactive. A usergroup which is inactive is visible in Cocuun but cannot be assigned to profiles of group folders or chat groups in Cocuun.</p>
          <p><b>Add User (optional)</b><br />
          Assign one or multiple users to the usergroup. If you want to create the usergroup without adding participants, you can skip this step. You can edit the usergroup later and add participants.</p>
          <p><b>Attention:</b> If you assign a user to a usergroup, he is automatically assigned to the folders and chat groups in Cocuun for which the usergroup is authorized.</p>
          <p><b>Add additional informations (optional)</b><br />
          Gather additional information for the assigend users. You can differentiate between the additional information only visible for administrators and the additional information visible for all member of the group folder.</p>
          <p><b>Set Responsibility</b><br />
          Define the users who are responsible for the usergroup. You can select users from your client that have the right “Can Manage Usergroups”. The users who are in charge of the usergroup are allowed to edit, compare or delete the usergroup.</p>
          <p><b>Overview</b><br />
          Press the button “Finish” to create the new usergroup.</p>
          <p>If you would like to change the information again, click on the corresponding tile. It leads you back to the step for editing.</p>`,
    },
    DELETE_USERGROUP: {
      de: `<p><b>Ausgewählte Benutzergruppe</b><br />
          Die Ansicht zeigt die Benutzergruppe, die Sie zum Löschen ausgewählt haben.</p>
          <p><b>Benutzer entfernen (optional)</b><br />
          Sie können bei entsprechender Berechtigung mit dem Löschen der Benutzergruppe auch einzelne Mitglieder aus dem Mandanten entfernen. Dies ist nur möglich, wenn der Benutzer ausschließlich Mitglied der ausgewählten Benutzergruppe ist. Ist er Mitglied in weiteren Benutzergruppen, kann er im Bereich „Benutzer“ aus dem Mandanten gelöscht werden. Möchten Sie die Benutzergruppe löschen, ohne den Benutzer vom Mandanten zu entfernen, überspringen Sie diesen Schritt.</p>
          <p><b>Zusammenfassung</b><br />
          Die Zusammenfassung zeigt, welche Benutzergruppe Sie löschen und welche Benutzer Sie ggf. aus dem Mandanten entfernen.</p>
          <p><b>Achtung:</b> Das Löschen von Benutzergruppen kann nicht rückgängig gemacht werden.</p>
          <p><b>Löschen bestätigen</b><br />
          Sofern Sie mit dem Löschen der Benutzergruppe auch Benutzer aus dem Mandanten entfernen möchten, müssen Sie dies mit der Eingabe Ihres Kennwortes bestätigen.</p>
          <p><b>Achtung:</b> Das Entfernen von Benutzern aus dem Mandanten kann nicht rückgängig gemacht werden. Die ausgewählten Benutzer werden aus allen Gruppenordnern und Gruppenchats des Mandanten entfernt.</p>`,
      en: `<p><b>Selected Usergroup</b><br />
          You can see the usergroup you want to delete.</p>
          <p><b>Remove Users (optional)</b><br />
          Having the needed permission allows you to remove several users from the client when deleting the usergroup. You can only remove users from the client, if he is exclusively part of the selected usergroup. As far as he is assigned to other usergroups as well, he can be removed from the client in the section “Users”. If you want to delete the usergroup without removing users from the client, you can skip this step.</p>
          <p><b>Overview</b><br />
          The overview displays the usergroup you want to delete and where applicable the users you want to remove from the client.</p>
          <p><b>Attention:</b> If you delete a usergroup, it cannot be revoked.</p>
          <p><b>Confirm Deletion</b><br />
          As soon as you want to remove users from the client when deleting the usergroup, you have to confirm the process with your password.</p>
          <p><b>Attention:</b> If you remove a user from the client, it cannot be revoked. The selected users are deleted from all the group folders and chat groups of the client.</p>`,
    },
    EDIT_USERGROUP: {
      de: `<p><b>Informationen anpassen (optional)</b><br />
          Bearbeiten Sie den Namen, die Beschreibung und das hinterlegte Bild der Benutzergruppe. Sie können den Aktivitätsstatus der Gruppe bearbeiten. Deaktivieren Sie eine Benutzergruppe, verbleibt sie in den bereits zugeordneten Profilen von Gruppenordnern und Gruppenchats in Cocuun, kann jedoch keinen neuen hinzugefügt werden. Auch als inaktive Benutzergruppe ist sie in Cocuun sichtbar.</p>
          <p><b>Benutzer zuordnen (optional)</b><br />
          Entscheiden Sie, welche Benutzer Sie aus der Benutzergruppe entfernen möchten und welche Sie der Benutzergruppe hinzufügen möchten.</p>
          <p><b>Achtung:</b> Ordnen Sie einen Benutzer einer Benutzergruppe zu, so wird er automatisch den Ordnern und Gruppenchats in Cocuun zugeordnet, in denen die Benutzergruppe berechtigt ist. Ihm stehen dann sofort die Inhalte zur Verfügung.</p>
          <p><b>Zusatzinformationen pflegen (optional)</b><br />
          Ändern Sie für jeden der Benutzergruppe zugeordneten Benutzer die weiteren Informationen. Sie können unterscheiden zwischen einer Zusatzinformation, die nur für Administratoren und einer, die für alle Teilnehmer eines Ordners sichtbar ist.</p>
          <p><b>Zuständigkeit festlegen (optional)</b><br />
          Passen Sie die Benutzer an, die Ihre Benutzergruppe pflegen sollen. Zur Auswahl stehen die Benutzer Ihres Mandanten, die das Recht „darf Benutzergruppen pflegen“ besitzen. Die für die Benutzergruppe Zuständigen dürfen die Benutzergruppe bearbeiten, vergleichen oder löschen.</p>
          <p><b>Zusammenfassung</b><br />
          Das Betätigen der Schaltfläche „Fertig“ übernimmt die von Ihnen vorgenommenen Änderungen, die in der Zusammenfassung nochmals angezeigt werden.</p>
          <p>Möchten Sie bestimmte Angaben doch noch einmal ändern? Klicken Sie auf eine Kachel, die Sie direkt zum entsprechenden Bearbeitungsschritt führt.</p>`,
      en: `<p><b>Edit information (optional)</b><br />
          Edit the name, the description and the picture of the usergroup. You can activate or deactivate the usergroup. If you deactivate the usergroup, it remains in the assigned group folders and chat groups in Cocuun but cannot be assigned to new group folders or chat groups. The usergroup is still visible in Cocuun.</p>
          <p><b>Assign Users (optional)</b><br />
          Select the users you want to delete from the usergoup and the ones you want to add to the usergroup.</p>
          <p><b>Attention:</b> If you assign a user to a usergroup, he is automatically assigned to the folders and chat groups in Cocuun, in which the usergroup is authorized.</p>
          <p><b>Maintain additional information (optional)</b><br />
          Maintain the additional informations of all group members. Differentiate between the additional information only visible for administrators and the additional information that is visible for all members of the group folder.</p>
          <p><b>Set Responsibility (optional)</b><br />
          You can adjust the users who you want to be responsible for managing the usergroup. You can select users from your client that have the right “Can Manage Usergroups”. The users who are in charge of the usergroup are allowed to edit, compare or delete the usergroup.</p>
          <p><b>Overview</b><br />
          Press the button “Finish” to confirm the changes.</p>
          <p>If you would like to change the information again, click on the corresponding tile. It leads you back to the step for editing.</p>`,
    },
    COMPARE_USERGROUPS: {
      de: `<p><b>Mitglieder bearbeiten</b><br />
          Nach der Auswahl zweier zu vergleichender Benutzergruppen zeigt die Ansicht zunächst die Benutzer, die beiden Benutzergruppen zugeordnet sind. Darunter sind die Benutzer aufgelistet, die nicht Teil beider Benutzergruppen sind.<br /> 
		      Mit einem Klick auf den Nutzer in der entsprechenden Spalte kann seine Zuordnung aufgehoben oder erteilt werden.</p>
          <p><b>Zusammenfassung</b><br />
          Das Betätigen der Schaltfläche „Fertig“ übernimmt die von Ihnen vorgenommenen Änderungen, die in der Zusammenfassung nochmals angezeigt werden.</p>
          <p>Möchten Sie bestimmte Angaben doch noch einmal ändern? Klicken Sie auf eine Kachel, die Sie direkt zum entsprechenden Bearbeitungsschritt führt.</p>`,
      en: `<p><b>Edit Members</b><br />
          After selecting two usergroups for the comparison, you can see the users who are part of both usergroups. Below, you can see the users who are only part of one of the two usergroups.<br /> 
		      With a click on the user in the corresponding column, you can cancel or grant the assignment to the usergroup.</p>
          <p><b>Overview</b><br />
          Press the button “Finish” to confirm the changes.</p>
          <p>If you would like to change the information again, click on the corresponding tile. It leads you back to the step for editing.</p>`,
    },
  },
  SETTINGS: {
    ABOUT_ADMINISTRATION: {
      de: 'Über Cocuun Administration',
      en: 'About Cocuun Administration',
    },
    SELECT_LANG: {
      de: 'Sprache wählen:',
      en: 'Select Language:',
    },
    CURRENT_VERSION: {
      de: 'Aktuelle Version: ',
      en: 'Current Version: ',
    },
    ON_NEWEST_VERSION: {
      de: 'Sie sind auf dem neuesten Stand!',
      en: 'You are on the newest version!',
    },
    CLEAR_CACHE: {
      de: 'Cache leeren',
      en: 'Clear Cache',
    },
    CHECK_FOR_UPDATES: {
      de: 'Auf Updates prüfen',
      en: 'Check for updates',
    },
    ALLOW_TOUCH_ID: {
      de: 'Anmeldung speichern:',
      en: 'Save Login:',
    },
    SAVE_LOGIN: {
      de: 'per Fingerabdruck',
      en: 'by fingerprint',
    },
  },
  UPDATE: {
    DLG_TITLE: {
      de: 'Neue Version verfügbar!',
      en: 'New Version Available!',
    },
    UPDATE_AVAILABLE: {
      de: `Es ist eine neue Version der Cocuun Administration verfügbar. Möchten Sie die Anwendung jetzt aktualisieren? Dabei wird die Seite neu geladen und Ihre ungespeicherten Daten gehen verloren.`,
      en: `New version of Cocuun Administration available. Do you want to update? After that the page will be reloaded and your unsaved changes will be lost.`,
    },
    NOT_RESPONSIBLE: {
      de: `Sie können die Mitglieder dieser Benutzergruppe nicht bearbeiten, da Sie nicht für diese zuständig sind.`,
      en: `You cannot edit the members of this usergroup, because you are not responsible.`,
    },
  },
  LOCK: {
    LOGIN_MSG: {
      de: 'Cocuun Administration',
      en: 'Cocuun Administration',
    },
  },
};
